@import "../../utils.scss";

$sidebarMargin: 227px; // TODO: can be extracted to a scss config!
$overviewPadding: 1.5rem;

.welcomePage {
  position: relative;
  background-color: $color-dark-two;

  &-overview {
    padding: $overviewPadding;
    display: grid;
    row-gap: 1.5rem;

    @include responsive($mobileL) {
      margin-left: 0;
    }
  }

  &-featured {
    &-wrapper {
      padding: 1.5rem;
      background: $color-dark-one;
      border-radius: 10px;

      @include responsive($mobileL) {
        display: none;
      }
    }
    &-iframe {
      height: 1400px;
      &::-webkit-scrollbar {
        width: 0;
      }
      overflow: hidden;
    }
    &-header {
      color: white;
      padding: 8px;
      position: relative;
      &-img {
        width: 150px;
        height: 150px;
        border-radius: 10px;
      }
      &-text {
        font-size: 50px;
        margin-left: 10px;
        vertical-align: bottom;
        font-family: Inter-SemiBold;
      }
      &-rightText {
        font-size: 18px;
        position: absolute;
        right: 10px;
        top: 10px;
        float: right;
      }
    }
    &-mobile {
      &-wrapper {
        text-align: center;
        padding: 1.5rem;
        background: $color-dark-one;
        border-radius: 10px;
        display: none;
        height: 2400px;

        @include responsive($mobileL) {
          display: grid;
        }

        .statistics-chart-box {
          width: calc(100% + 3rem);
          left: -1.5rem;
        }
      }
      &-header {
        color: white;
        font-size: 1.4em;
        white-space: nowrap;
        font-family: Inter;
        font-style: normal;
        margin-bottom: 10px;

        &-tryanother {
          font-size: 0.7em;
        }
      }
      &-img {
        width: 80%;
        border-radius: 5px;
        margin-bottom: 20px;
      }
    }
  }

  &-header {
    position: relative;
    display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 1.5rem;
    column-gap: 1.5rem;

    justify-content: left;

    width: 100%;

    &-right {
      position: absolute;
      float: right;
      right: 0;

      @include responsive($mobile) {
        width: 100%;
      }
    }

    .wpGoodMorning {
      position: relative;
      display: inline-block;
      width: auto;
      min-width: 270px;
      height: 99px;

      background: #1d2025;
      border-radius: 10px;

      line-height: 6px;
      padding: 22px;

      @include responsive($mobile) {
        width: 100%;
      }

      &-greeting {
        position: relative;
        width: 150px;
        // height: 22px;
        // left: 10px;
        line-height: 10px;

        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;

        margin-bottom: 0;
        // line-height: 134.52%;
        /* identical to box height, or 22px */

        color: #8e8f92;
      }

      &-artistName {
        position: relative;
        display: inline;
        width: 156px;
        height: 43px;
        // left: 10px;
        line-height: 50px;

        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 32px;
        /* identical to box height, or 43px */

        color: #ffffff;

        white-space: nowrap;

        margin-block-start: 0;
        margin-block-end: 0;
        margin-inline-start: 0px;
        margin-inline-end: 0px;

        @include responsive($mobile) {
          font-size: 7vw;
        }
      }

      &-dots {
        position: absolute;
        top: 15px;
        right: 20px;
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 134.52%;

        cursor: pointer;
      }
    }
  }

  &-boxes {
    // display: inline-flex;
    // flex-direction: row;
    // flex-wrap: wrap;
    row-gap: 1.5rem;
    // column-gap: 1.5rem;

    justify-content: center;

    .wpBox {
      position: relative;
      display: inline-block;
      width: auto;
      min-height: 398px;
      min-width: 280px;
      // max-width: 580px;
      background-size: cover !important;
      max-height: 237px;
      overflow: hidden;
      width: 100%;
      height: 100%;
      background: #1d2025;
      border-radius: 10px;
      z-index: 0;
      cursor: pointer;

      &:hover {
        box-shadow: 8px 8px 16px 0 rgba(0, 0, 0, 0.2);
        transition: all 0.3s;
      }

      &:hover {
        box-shadow: 8px 8px 16px 0 rgba(0, 0, 0, 0.2);
        transition: all 0.3s;     
      }

      @include responsive($mobile) {
        min-width: 0;
      }

      $picHeight: 237px;

      &-content {
        bottom: 58px;
        position: absolute;
        width: 91%;
        @include responsive($mobileL) {
          width: 100%;
        }
        &-wrapper {
          position: absolute;
          top: 0;
          height: 100%;
          width: 100%;
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
          background: linear-gradient(0deg, #1d2025 25%, #1d20250a 100%);
        }
      }

      &-tag {
        position: absolute;
        display: block;
        width: fit-content;
        block-size: fit-content;
        height: 56px;
        top: 16px;
        right: 0;
        z-index: 1;
        line-height: 22px;

        padding-left: 32px;

        overflow: auto;

        background: #0ef1db;

        border-radius: 10px 0 0 10px;

        margin-top: 20px;

        box-shadow: 0px 4px 64px rgba(21, 22, 28, 0.7);

        &-header {
          position: relative;

          right: 16px;

          float: right;

          font-family: Inter-Medium;
          font-style: normal;
          font-size: 20px;
          line-height: 134.52%;
          /* identical to box height, or 27px */

          color: #1d2025;
        }

        &-sub {
          position: relative;

          top: 5px;
          right: 16px;

          float: right;

          font-family: Inter-Medium;
          font-style: normal;
          font-size: 12px;
          line-height: 134.52%;
          /* or 16px */

          color: #1d2025;
        }

        &-headerCentered {
          position: relative;

          top: 15px;
          right: 16px;

          float: right;

          font-family: Inter-Medium;
          font-style: normal;
          font-size: 20px;
          line-height: 134.52%;
          /* identical to box height, or 27px */

          color: #1d2025;
        }
      }

      &-text {
        &-header {
          position: relative;

          @include center;

          font-family: Inter-Bold;
          font-style: normal;
          font-weight: 500;
          font-size: 2rem;
          line-height: 134.52%;

          color: #ffffff;

          margin-bottom: 8px;

          @include responsive($mobileL) {
            font-size: 1.5rem;
            line-height: 1.5;
          }
        }

        position: relative;
        padding: 0 30px 0 30px;
        @include responsive($mobileL) {
          padding: 0 16px;
          font-size: 1rem;
        }

        @include center;

        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 1.05rem;
        line-height: 134.52%;
        margin-top: 20px;
        color: #ffffff;

        &-grey {
          color: #777; 
          font-weight: 600;
         
        } 
      
      }

      &-actions {
        position: absolute;
        bottom: 6px;
        height: 24px;
        width: 100%;
        // background-color: red;

        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 134.52%;
        /* or 24px */

        cursor: pointer;

        &-left {
          text-decoration: underline;
          position: absolute;
          left: 30px;
        }

        &-right {
          text-decoration: underline;
          position: absolute;
          right: 30px;
        }

        &-primary {
          color: #0ef1db;
        }

        &-grey {
          color: #757f8f;
        }
      }
    }
  }

  &-big {
    display: flex;
    row-gap: 1.5rem;

    justify-content: center;

    &-modal {
      position: relative;
      height: auto;
      width: auto;
      max-width: 1170px;

      &-background {
        position: relative;
        height: auto;
        width: 100%;
        z-index: 0;

        overflow: hidden;

        @include responsive($mobile) {
          height: 200px;
        }
      }

      &-content {
        position: absolute;
        width: 100%;
        top: 0;
        width: auto;

        padding: 7%;

        @include center;

        line-height: 5vw;

        @include responsive(1420px) {
          left: 32%;
        }

        @include responsive($mobileL) {
          left: 35%;
        }
        @include responsive($mobile) {
          top: auto;
          bottom: 1vw;
          @include center;
        }

        @include responsive(305px) {
          @include center;
        }

        &-header {
          width: 100%;

          line-height: 15px;

          position: relative;
          z-index: 100;

          font-size: 5vw;

          white-space: nowrap;

          z-index: 999;

          font-family: Inter-Bold;
          color: white;

          @include responsive(1024px) {
            font-size: 4vw;
            top: -15px;
          }

          @include responsive($mobileL) {
            font-size: 6vw;
            top: 0;
          }

          @include responsive($mobile) {
            font-size: 24px;
            line-height: 25px;
            top: -5px;
          }

          &-x {
            font-size: 4vw;
            position: relative;
            bottom: 0.7vw;
            left: 0.9vw;
            margin-right: 0.8vw;

            color: #ff3a81;

            @include responsive(1024px) {
              font-size: 3.5vw;
              margin-right: 0.6vw;
            }
          }

          &-info {
            width: 24px;
            height: 24px;

            position: relative;

            margin-left: 4px;
            margin-bottom: 1vw;

            cursor: pointer;

            @include responsive($mobile) {
              width: 15px;
              height: 15px;
            }
          }
        }

        &-btn {
          position: relative;
          z-index: 100;

          width: 18vw;
          max-width: 197px;
          height: 5vw;
          max-height: 56px;

          @include center;

          font-family: Inter-Medium;
          font-style: normal;
          font-size: 18px;

          text-align: center;

          color: #151619;

          @include responsive(1420px) {
            top: 10px;
          }

          @include responsive(1024px) {
            top: -10px;
          }

          @include responsive($mobileL) {
            top: 10px;
            height: 40px;
          }

          @include responsive($mobile) {
            top: 0px;
            font-size: 14px;
            height: 35px;
            width: 100px;
          }
        }
      }

      &-tag {
        position: absolute;
        display: inline-block;
        width: fit-content;
        block-size: fit-content;
        height: 56px;
        top: 36px;
        right: 0;

        padding-left: 32px;

        background: #0ef1db;

        border-radius: 10px 0 0 10px;

        box-shadow: 0px 4px 64px rgba(21, 22, 28, 0.7);

        @include responsive(1024px) {
          padding-left: 20px;
          height: 50px;
        }

        @include responsive($mobileL) {
          line-height: 17px !important;
          height: 40px;
        }

        @include responsive($mobile) {
          top: 25px;
        }

        @include responsive(350px) {
          line-height: 17px !important;
          height: 40px;
        }

        &-header {
          position: relative;

          right: 16px;
          top: 6px;

          float: right;

          font-family: Inter-Medium;
          font-style: normal;
          font-size: 20px;
          line-height: 134.52%;
          /* identical to box height, or 27px */

          color: #1d2025;

          @include responsive(1024px) {
            font-size: 15px;
            left: -1vw;
          }

          @include responsive($mobileL) {
            font-size: 11px;
            left: -1vw;
          }

          @include responsive($mobile) {
            font-size: 10px;
          }
        }

        &-sub {
          position: relative;

          top: 5px;
          right: 16px;

          float: right;

          font-family: Inter-Medium;
          font-style: normal;
          font-size: 12px;
          line-height: 134.52%;
          /* or 16px */

          color: #1d2025;

          @include responsive(1024px) {
            font-size: 1.4vw;
            left: -1vw;
          }

          @include responsive($mobile) {
            font-size: 8px;
          }
        }
      }
    }
  }

  &-suggested {
    display: flex;
    justify-content: center;
    width: 100%;

    &-modal {
      width: 100%;
      // height: 300px;
      background: #1d2025;
      border-radius: 10px;
      overflow-x: auto;
      overflow-y: hidden;
      &::-webkit-scrollbar {
        display: none;
      }
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none;

      @include responsive($mobileL) {
        overflow-x: hidden;
      }
    }

    &-header {
      position: relative;

      // top: 24px;
      left: 24px;

      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 134.52%;
      /* identical to box height, or 22px */

      color: #8e8f92;
    }

    $itemWidth: 180px;
    $columnGap: 3.0625rem;

    &-items {
      overflow: visible;
      justify-content: center;
      flex-flow: wrap;

      position: relative;

      display: flex;
      flex-direction: row;
      flex-shrink: initial;
      row-gap: 25px;

      padding-top: 5px;

      &-spinner {
        width: 200px;
        &-wrapper {
          width: 100%;
          display: flex;
          justify-content: center;
        }
      }
    }

    &-item {
      width: $itemWidth;
      justify-content: center;
      text-align: center;
      margin: 0 $columnGap / 2;

      flex: 1 1 190px;

      @include responsive($mobileL) {
        // margin: 0;
        margin: 0 2vw;
        width: auto;
      }

      &-img {
        position: relative;

        img {
          border-radius: 10px;
          z-index: 10;
          object-fit: cover;
          width: $itemWidth;
          height: 180px;

          &:hover {
            border: 4px solid $primary;
          }

          @include responsive($mobileL) {
            width: 34vw;
            height: 34vw;
          }
        }
      }

      // &-shade {
      //   position: absolute;
      //   width: 100%;
      //   height: 100%;
      //   background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);

      //   z-index: 50;

      //   bottom: 0;
      // }

      &-name {
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 134.52%;

        color: #ffffff;

        top: 80px;
        left: 13px;

        position: absolute;

        z-index: 997;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 150px;

        @include responsive($mobileL) {
          top: 15vw;
          font-size: 12px;
          height: 100%;
          position: absolute;
        }
      }
    }
  }
}

.wpPaymentModal {
  &-modal {
    // padding: 40px 0 0 0;
    display: block;
  }
  &-header {
    position: relative;
    width: 100%;

    top: 0;

    display: block;
    @include center;
  }
  &-sub {
    margin-top: 4px;
    font-size: 16px;
  }
  &-picker {
    top: -5px;
  }
  &-methodPicker {
    // padding-top: 10%;

    &-loading {
      width: 100%;
      img {
        position: relative;
        width: 50%;
      }
    }
  }
}

.customAlert {
  span {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    color: black;
    font-size: 14px;

    @include responsive(480px) {
      font-size: 9px;
    }
  }

  &-resend {
    display: table;
    color: black;
    border: 1px solid black;
    border-radius: 5px;
    width: auto;
    padding: 2px 8px 2px 8px;
    margin-top: 5px;
    cursor: pointer;
    background-color: rgba(224, 224, 224, 0.7);

    @include responsive($mobile) {
      font-size: 9px;
    }

    &:hover {
      background-color: white;
    }

    &-relativeCenter {
      position: relative;
      @include center;
    }
  }
}


