@import "./utils.scss";

html,
body {
  margin: 0;
  padding: 0;
  font-family: "FiraSansLight" !important;
  background: #343a47;
  scroll-behavior: smooth;
}

.xyFlexCenter {
  @include xyFlexCenter;
}

.nonSelect {
  @include nonSelect;
}

.noWrap {
  white-space: nowrap;
}

.smallEdit {
  margin-top: 0.4rem;
  text-decoration: underline;
  text-decoration-style: dotted;
  color: $primary;
  cursor: pointer;
}

.textColorPrimary {
  color: $primary;
}

a {
  color: $primary;
  text-decoration: underline;

  // -webkit-user-select: none; /* Safari */
  // -moz-user-select: none; /* Firefox */
  // -ms-user-select: none; /* IE10+/Edge */
  // user-select: none; /* Standard */

  &:hover {
    color: $primary;
  }
}

body {
  overflow-x: hidden;
}

.preventOverflowY {
  overflow-y: hidden;

  @include responsive($mobileL) {
    overflow-y: scroll;
  }

  @include responsive($mobile) {
    overflow-y: scroll;
  }
}

.App {
  text-align: center;
  background-color: $background;
}

.App-header {
  height: 220px;
  padding-top: 76px;
  text-align: right;
  width: 75%;
  align-items: center;
  display: inline-block;
}

.fontFiraLight {
  font-family: "FiraSansLight";
}

.Logo {
  width: 100px;
  height: 100px;
  display: inline-block;
  float: left;
  color: white;
  text-align: center;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
input:-internal-autofill-selected {
  -webkit-box-shadow: 0 0 0 50px $color-dark-two inset !important;
  -webkit-text-fill-color: white;
}

.fontFiraLight {
  font-family: "FiraSansLight";
}

.fontIndustry {
  font-family: "Industry";
}

.headerName {
  font-size: 38px;
}

.headerLabel {
  font-size: 20px;
}

.dark {
  background-color: #383b3a !important;
  color: white !important;
}

.App-content {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  // margin-top: 40px;
  /* justify-content: center; */
  font-size: calc(10px + 2vmin);
  color: $secondary;
}

.contentWrapper {
  width: 70%;
}

.graphWrapper {
  min-height: 300px;
  height: 90%;
}

.xyCenter {
  @include xyFlexCenter;
}

.playtreksBigSpinner {
  width: 10vw;
}

.graph {
  height: 600px;
  width: 1300px;
}

.App-link {
  color: #61dafb;
}

.ptCenteredSpinner {
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  z-index: 999;
  width: 200px;
  height: 200px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media only screen and (max-height: 728px) {
  .Logo {
    width: 50px;
    height: 50px;
  }

  .App-header {
    padding-top: 30px;
    height: auto;
  }
  .headerName {
    font-size: 18px;
    margin-bottom: 0;
  }
  .headerLabel {
    font-size: 12px;
  }
  body {
    // line-height: 1 !important;
  }
}

.greyModal {
  // background-color: grey;
  // opacity: 0.5;
  // margin-top: 150px;
  // width: 70vw;
  // height: 70vh;
  padding-top: 150px;
}

.opacity {
  opacity: 0.3;

  &-50 {
    opacity: 0.5;
  }
}

.textLink {
  color: white;
  cursor: pointer;

  @include responsive($mobileL) {
    text-decoration: underline;
  }

  &:hover {
    color: $primary !important;
    text-decoration: underline;
  }

  &-underline {
    text-decoration: underline;
  }
}

.text {
  color: white;
}

/* SPINNER */

.lds-default {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  z-index: 9999;
}
.lds-default div {
  position: absolute;
  width: 6px;
  height: 6px;
  background: #fff;
  border-radius: 50%;
  animation: lds-default 1.2s linear infinite;
}
.lds-default div:nth-child(1) {
  animation-delay: 0s;
  top: 37px;
  left: 66px;
}
.lds-default div:nth-child(2) {
  animation-delay: -0.1s;
  top: 22px;
  left: 62px;
}
.lds-default div:nth-child(3) {
  animation-delay: -0.2s;
  top: 11px;
  left: 52px;
}
.lds-default div:nth-child(4) {
  animation-delay: -0.3s;
  top: 7px;
  left: 37px;
}
.lds-default div:nth-child(5) {
  animation-delay: -0.4s;
  top: 11px;
  left: 22px;
}
.lds-default div:nth-child(6) {
  animation-delay: -0.5s;
  top: 22px;
  left: 11px;
}
.lds-default div:nth-child(7) {
  animation-delay: -0.6s;
  top: 37px;
  left: 7px;
}
.lds-default div:nth-child(8) {
  animation-delay: -0.7s;
  top: 52px;
  left: 11px;
}
.lds-default div:nth-child(9) {
  animation-delay: -0.8s;
  top: 62px;
  left: 22px;
}
.lds-default div:nth-child(10) {
  animation-delay: -0.9s;
  top: 66px;
  left: 37px;
}
.lds-default div:nth-child(11) {
  animation-delay: -1s;
  top: 62px;
  left: 52px;
}
.lds-default div:nth-child(12) {
  animation-delay: -1.1s;
  top: 52px;
  left: 62px;
}
@keyframes lds-default {
  0%,
  20%,
  80%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
}

.playtreksCheckboxText {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 134.52%;
  /* or 16px */

  color: #ffffff;

  opacity: 0.6;

  cursor: pointer;
}

.mobile480visible {
  display: none;

  @include responsive($mobile) {
    display: block;
  }
}

.mobile480invisible {
  @include responsive($mobile) {
    display: none;
  }
}

.mobile768visible {
  display: none;

  @include responsive($mobileL) {
    display: block;
  }

  &-flex {
    display: none;

    @include responsive($mobileL) {
      display: flex;
    }
  }
}

.mobile768invisible {
  display: block;

  @include responsive($mobileL) {
    display: none;
  }
}

.includeCenter {
  @include center;
}

.toastrStyleAlert {
  &-wrapper {
    position: fixed;
    z-index: 9999;
    @include center;

    @include responsive($mobile) {
      left: auto;
      transform: none;
    }
  }

  &-pink {
    background-color: #d35c8c !important;
  }

  margin-bottom: 10px;
  z-index: 9999;
  float: right;

  &-sidebarMargin {
    margin-left: 120px;

    @include responsive($mobileL) {
      margin-left: auto;
    }
  }

  @include responsive($mobile) {
    right: auto;
    float: center;
    line-height: 15px;
  }

  &-opacity {
    opacity: 0.8;
  }

  &-x {
    position: relative;
    margin-left: 25px;
    cursor: pointer;
    width: 20px;
    filter: invert(1);
  }
}

.contactSupportButton {
  position: fixed;
  bottom: 20px;
  left: 20px;
  text-align: left;
  font-size: 15px;
  color: #8e8f92;
  cursor: pointer;
  z-index: 9999;

  @include responsive($mobileL) {
    position: fixed;
    bottom: 10px;
    text-align: center;
    width: 90%;
  }
}

.adminInputWrapper {
  position: relative;
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}

// MUI checkboxes override
// appears on subscriptions page

.MuiSvgIcon-root {
  color: $primary !important;
  width: 1.2em !important;
  height: 1.2em !important;
}

.opacity {
  &-0 {
    opacity: 0;
  }
  &-07 {
    opacity: 0.7;
  }
  &-08 {
    opacity: 0.8;
  }
  &-05 {
    opacity: 0.5;
  }
}

.interBold {
  font-family: Inter-Bold;
}

.text-line-through {
  text-decoration: line-through;
}

.text-inactive {
  color: $color-light-two;
}

.noBoxShadow {
  box-shadow: none;
}

.inputUpperComment {
  position: absolute;

  z-index: 100;

  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 134.52%;
  /* or 16px */

  color: #ffffff;

  opacity: 0.5;

  margin-left: 12px;
  margin-top: 4.5px;

  @include nonSelect;
}

.plotlines-tooltip {
  position: absolute;
  font-family: "Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica,
    sans-serif;
  font-size: 12px;
  white-space: nowrap;
  color: white;
  cursor: default;
  margin-left: 0px;
  margin-top: 0px;
  left: 8px;
  top: 8px;
  border: 1px solid #e79a0a;
  border-radius: 4px;
  padding: 5px;
  z-index: 10000;
}

.font-family {
  &-Inter {
    font-family: Inter, sans-serif;
  }
}

.text-decoration {
  &-underline {
    text-decoration: underline;
  }
}

.btn-secondary.disabled {
  cursor: initial;
}

.fs {
  &-12 {
    font-size: 0.75rem;
  }
  &-14 {
    font-size: 0.875rem !important;
  }
  &-16 {
    font-size: 1rem;
  }
  &-24 {
    font-size: 1.5rem !important;
  }
}

.cursor {
  &-default {
    cursor: default;
  }
}

.overflow {
  &-x {
    &-scroll {
      overflow-x: scroll;
    }
  }
}

.select-v2 {
  &__option {
    &:active {
      background-color: #33383f !important;
    }
  }
}
.overrule-row-grid {
    display: flex;
    flex-direction: row;
    flex-basis: 100%;
    flex: 1;
    gap: 10px;
    flex-wrap: wrap;
}
.overrule-row-grid .cursor-pointer {
    position:absolute;
}
.overrule-row-grid .nrPrimaryArtistInputOuterWrapper, .overrule-row-grid .nrContributorsOuterWrapper{
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
    position:static ;
    @media only screen and (max-width: 768px) {
        flex-basis: 100%;
    }
}
.overrule-row-grid .nrExpFieldsHint {
    position:static;
    opacity:1;
}

