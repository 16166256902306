@import "../../utils.scss";

.paperWrapper {
  width: 80%;
}

.boxRow {
  height: 110px;
}

.item {
  background-color: $darkerbackground;
}

.leftPane {
  font-size: 0.9em;
  text-align: left;
  padding-left: 1.2em !important;
  // border-right: 1px solid #dee2e6;
}

.darker {
  background-color: $darkerbackground !important;
}

.landingBox {
  height: 300px;
  width: 200px;
}

.landingDescription {
  display: table-cell;
  vertical-align: middle;
  font-size: 1rem;
}

.colorPrimary {
  color: $primary;
}

.colorSecondary {
  color: $secondary;
}

.react-select__indicator-separator {
  background-color: #545454 !important;
}

.small {
  font-size: 0.6em !important;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: $background;
}

::-webkit-scrollbar-thumb {
  background: $secondary;
  border-radius: 10px;
}

.playlistsContainer {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: baseline;
  &-colorCircle {
    height: 8px;
    width: 8px;
    border-radius: 50%;
    display: inline-block;
    color: black;
    text-align: center;
    vertical-align: middle;
    padding: 3.2px;
    font-size: 1.1em;
  }
  &-trackSeen {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 134.52%;
    color: $color-light-two;
  }
  &-trackName {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    color: white;
  }
  &-title {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 134.52%;
    color: $color-light-two;
    text-align: right !important;
  }
  &-value {
    font-size: 12px;
  }
}
// .album-artist {
//   font-family: Inter;
//   font-style: normal;
//   font-weight: 500;
//   font-size: 11px;
//   line-height: 15px;
//   color: white;

//   position: relative;
//   left: 15px;
//   top: 2px;
// }

.icon-label {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 134.52%;
  cursor: pointer;
  margin-left: 6px;
}

/* ENTITY PANEL  */

.entityPanel {
  width: 100%;
  height: 120px;
  background-color: grey;
  margin-right: none !important;
  margin-left: none !important;
  background-color: $background;
  font-size: 0.8em;
  margin-bottom: 1.2em;
  margin-top: 1.1em;
}

.entityPicWrap {
  height: 90px;
  width: 90px;
  padding-right: 0 !important;
}

.entityPicture {
  height: 90px;
  width: 90px;
  background-color: $background;
  text-align: center;
  overflow: hidden;
}

.entityImg {
  max-width: 100%;
  height: auto;
}

.entityBadge {
  width: 100%;
  height: 70%;
  margin-top: 15%;
  background-color: $primary !important;
  font-family: "Industry";
  font-size: 1rem;
  text-align: center;
  display: table;
}

.entityBadgeText {
  display: table-cell;
  vertical-align: middle;
}

.entityDescription {
  text-align: left;
  font-size: 1em;
  color: white;
  margin-top: -30px;
  margin-left: 14px;
}

.entityName {
  font-size: 1.4em !important;
  color: $primary;
}

.rightPane {
  min-height: 200px;
  padding-left: 0 !important;
}

.industryFont {
  font-family: "Industry";
}

.graphLabel {
  width: 100%;
  // border-bottom: 1px solid #dee2e6;
  font-size: 0.6em;
  text-align: left;
}

.playlistImageWrap {
  padding-right: 0 !important;
  padding-left: 0 !important;
  width: 90px;
  height: 90px;
  margin-right: 9px;
  margin-bottom: 9px;
}

.playlistImage {
  width: auto;
  height: auto;
  max-width: 100%;
  /* max-height: 100%; */
}

.spotifyLogo {
  margin-top: -5px;
}

.spotifyLogo > img {
  width: 60px;
  height: auto;
}

@media only screen and (max-width: 1200px) {
  .leftPane {
    border-right: none;
  }

  .rightPane {
    min-height: 200px;
    padding-right: 0 !important;
    margin-top: 20px !important;
  }
}

@media only screen and (max-width: 768px) {
  .leftPane {
    border-right: none;
  }

  .rightPane {
    min-height: 200px;
  }

  .contentWrapper {
    width: 80%;
  }

  .entityPanel {
    margin-top: 10px;
    height: 60px;
  }

  .entityPicWrap {
    padding-left: 0 !important;
  }

  .entityPicture {
    width: 50px !important;
    height: 50px !important;
  }

  .entityName {
    line-height: 0 !important;
  }

  .entityDescription {
    font-size: 0.8em;
    margin-top: -110px !important; // hacky
    margin-left: 58px !important;
    // padding-left: 4px !important;
  }

  .entityBadge {
    font-size: 0.6rem;
    // width: 70px !important;
    height: 60px !important;
    margin: 0 !important;
  }

  .boxRow {
    padding-right: 0 !important;
    padding-left: 0 !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    height: auto;
  }

  .box1 {
    padding-left: 0 !important;
  }

  .box3 {
    padding-right: 0 !important;
  }

  .box1,
  .box2,
  .box3 {
    padding-right: 0 !important;
  }

  .playlistsPanel {
    margin-bottom: 20px;
  }

  .colorCircle {
    width: 2em;
    height: 2em;
    padding: 0;
    padding-top: 5.5px;
    margin-bottom: 5px;
  }

  .spotifyLogo {
    margin-top: 0px;

    > img {
      width: 40px;
      height: auto;
    }
  }
}

.playlistDescription {
  // font-size: 70%;
  margin-top: -2px;
  line-height: 20px;
}

@media only screen and (min-width: 396px) and (max-width: 767.98px) {
  .entityDescription {
    margin-top: 20px !important;
    margin-left: -30px !important;
  }
}
