$primaryColor: #0ef1db;
$darkColor: #1d2025;
$grey: #33383f;
$lightGrey: rgba(255, 255, 255, 0.44);

$mobile: 480px;
$mobileL: 768px;
$dSmall: 1024px;

@mixin responsive($query) {
  @media (max-width: + $query) {
    @content;
  }
}

@mixin center() {
  left: 50%;
  transform: translate(-50%, 0);
}

// Upload Tracks screen

.utTrackUploadedAlert {
  background-color: greenyellow !important;
  color: black;
  position: fixed;
  @include center;
  margin-top: 20px;
  z-index: 99999;
  opacity: 0;
  animation: fadeinout 5s ease-out;
}

@-webkit-keyframes fadeinout {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@keyframes fadeinout {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.uppy-Dashboard-browse {
  color: #0ef1db !important;
}

.utDropzone {
  cursor: pointer;
  position: absolute;
  width: 864px;
  height: 300px;
  top: 439px;

  @include center;

  // background: #1d2025;
  border-radius: 10px;

  @include responsive($dSmall) {
    width: 90%;
  }

  &-loadingWrapper {
    width: 100%;
    height: 70%;
    margin-top: 3.5%;

    &-spinner {
      position: relative;
      width: 100%;
      height: 100%;
    }

    &-text {
      margin-top: 1%;
      text-align: center;
      color: white;
    }
  }
}

.utTrackErrorMsg {
  position: relative;
  bottom: 40px;
  text-align: center;
  color: red;
}

.utDropzoneInput {
  height: 100%;
}

.utDropzoneText1 {
  position: relative;
  top: 80px;
  //   left: 324px;
  width: 100%;

  height: 19px;

  font-family: Inter-Light;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 134.52%;
  /* or 24px */

  text-align: center;

  color: #ffffff;

  opacity: 0.5;
}

.utDropzoneButtonWrapper {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.utDropzoneButton {
  position: relative;

  top: 100px;

  width: 259px;
  height: 56px;

  background: $grey;
  border-radius: 10px;

  cursor: pointer;

  color: $primaryColor;

  font-family: Inter-Medium;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 134.52%;
  /* or 24px */

  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.utDropzoneText2 {
  position: relative;

  top: 120px;

  width: 100%;
  height: 19px;

  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 134.52%;
  /* identical to box height, or 19px */

  text-align: center;

  color: #ffffff;

  opacity: 0.5;

  @include responsive($mobile) {
    padding: 8px;
  }
}

.utDndWrapper {
  position: absolute;
  top: 800px;
  width: 100%;
  // text-align: center;
  justify-content: center;
  align-items: center;
}

.utDndContainer {
  position: relative;
  width: 864px;

  padding-left: 0 !important;

  @include responsive($dSmall) {
    width: 90%;
  }
}

.utDndItem {
  width: 864px;
  height: 58px;

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 18px 0px 0px 16px;

  background: #1d2025;
  border-radius: 10px;

  margin-bottom: 20px;

  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 134.52%;
  /* identical to box height, or 22px */

  color: #ffffff;

  cursor: pointer;

  .handleText {
    margin-top: 2px;
  }

  -webkit-user-select: none;
  user-select: none;

  @include responsive($dSmall) {
    width: 100%;
  }

  @include responsive($mobileL) {
    margin-bottom: 30px;
  }
}

.utDndHandle {
  cursor: move;
  cursor: grab;
  margin-right: 20px;
}

.cursorDragging {
  cursor: grabbing;
}

.utExpandedPanel {
  position: relative;
  min-height: 980px;
  padding-top: 12px;

  @include responsive($mobileL) {
    margin-bottom: 350px;
  }
}

.utepSongTitleInputWrapper {
  position: absolute;
  width: 400px;
  height: 56px;

  @include responsive($dSmall) {
    width: 47.5%;
  }

  @include responsive($mobileL) {
    width: 100%;
  }
}

.utepTrackVersionInputWrapper {
  position: absolute;
  width: 400px;
  height: 56px;
  right: 0;

  @include responsive($dSmall) {
    width: 50%;
  }

  @include responsive($mobileL) {
    float: left;
    right: auto;
    left: 0;
    top: 103px;
    width: 100%;
  }
}

.utepHr {
  position: relative;
  width: 864px;
  height: 0px;
  top: 96px;

  z-index: 0;

  opacity: 0.4;
  border: 1px solid #151618;

  @include responsive($dSmall) {
    width: 100%;
  }

  @include responsive($mobileL) {
    margin-top: 90px;
    margin-bottom: 0;
  }
}

.releaseCardLine {
  width: 100%;

  opacity: 0.4;
  height: 2px;
  background-color: #a5aaa8;

  @include responsive($dSmall) {
    width: 100%;
  }

  @include responsive($mobileL) {
    margin-bottom: 0;
    width: 100%;
  }
}

.utepInput {
  background-color: #1d2025;
  border: none;
  height: 100%;
  border-radius: 10px;

  height: 56px;

  font-size: 16px;
  font-family: Inter-Light;

  &:hover,
  &:active,
  &:focus {
    background-color: #1d2025;
    // color: #8e8f92;
    color: white;
  }

  &:focus,
  &:active {
    border: 1px solid #0ef1db;
    box-sizing: border-box;
  }
}

.utepPrimaryArtistInputOuterWrapper {
  position: relative;
  width: 100%;
  height: 56px;
  top: 139px;

  @include responsive(963px) {
    margin-bottom: 20px;
    margin-left: 2.5vw;
  }

  @include responsive($mobileL) {
    margin-left: 0;
    margin-bottom: 130px;
  }
}

.utepPrimaryArtistWrapper {
  position: relative;
  width: 45%;
  margin-bottom: 42px;

  @include responsive(963px) {
    line-height: 70px;
    top: -10px;
    margin-bottom: 10px;
  }

  @include responsive($mobileL) {
    top: 0;
    line-height: 65px;
    margin-bottom: 80px;
  }
}

.utRowDummy {
  position: relative;
  width: 400px;
  height: 89px;
  z-index: -999;

  @include responsive(963px) {
    height: 165px;
    width: 100%;
  }

  @include responsive($mobileL) {
    height: 280px;
  }

  &-2 {
    @include responsive(963px) {
      height: 150px !important;
    }

    @include responsive($mobileL) {
      height: 359px !important;
    }
  }
}

// fixer classes for expandable fields

.utepCommentFieldFixer {
  top: -15px;

  &-contr {
    top: 4px;

    &-name {
      @include responsive($mobileL) {
        bottom: auto;
        top: 80px !important;
      }
    }

    &-email {
      @include responsive($mobileL) {
        bottom: auto;
        top: 149px !important;
      }
    }

    &-share {
      @include responsive($mobileL) {
        bottom: auto;
        top: 238px !important;
      }
    }
  }

  @include responsive(963px) {
    top: 10px;
  }

  @include responsive($mobileL) {
    top: -23px;
  }
}

.utepFieldFixer {
  position: absolute !important;
  top: -34px;
}

.utiuc3 {
  @include responsive($mobileL) {
    top: 0;
  }
}

.utiuc3a {
  @include responsive(963px) {
    left: 45vw;
  }

  @include responsive($mobileL) {
    left: 0;
    top: 70px;
  }
}

.utiuc3b {
  @include responsive(963px) {
    top: 78px;
    z-index: 9999;
  }

  @include responsive($mobileL) {
    left: 0;
    top: 160px;
  }
}

.utiuc4 {
  @include responsive(963px) {
    top: 5px;
  }
}

.utiuc4a,
.utiuc4b {
  @include responsive(963px) {
    top: 10px;
    z-index: 9999;
  }
}

.utiuc4c {
  @include responsive(963px) {
    top: 78px;
    z-index: 9999;
  }
}

///

.utepPrimaryArtistInputGroup {
  position: relative;
  display: inline-flex;
  margin-bottom: 27px;
  margin-right: 14px;
  width: 314px;

  // same props as utepFieldFixer, hacky here because it's not possible to add that class to the select creatable input
  .ruslanSelect__control {
    position: absolute !important;
    top: -34px;
    width: 100%;
  }

  // menu position fix for creatable field
  .ruslanSelect__menu {
    margin-top: 30px;
  }

  @include responsive(963px) {
    width: 42.5vw;
  }

  @include responsive($mobileL) {
    width: 100%;
    margin-bottom: 8px;
  }
}

.utepOptionRemoveFixer {
  top: 5px !important;
  @include responsive(963px) {
    top: -15px !important;
  }
}

.utepPrimaryArtistEmailInputGroup {
  position: relative;
  display: inline-flex;
  margin-right: 14px;
  width: 336px;

  @include responsive(963px) {
    margin-right: 0;
    margin-left: 1.1vw;
    width: 39.2vw;
  }

  @include responsive($mobileL) {
    width: 100%;
    margin-left: 0;
  }
}

.utepPrimaryShareInputGroup {
  position: relative;
  display: inline-flex;
  width: 115px;
  z-index: 999;

  @include responsive($dSmall) {
    width: 115px;
  }

  @include responsive($mobileL) {
    top: 25px;
  }
}

.utepShareFlex {
  @include responsive($dSmall) {
    width: 180px;
  }

  &-2 {
    @include responsive($dSmall) {
      width: 179px;
    }
  }
}

.utepPlus {
  position: absolute;
  width: 56px;
  height: 56px;
  float: right;
  right: 0;
  top: -16px;
  bottom: 0;

  background: #1d2025;
  border-radius: 10px;

  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  /* identical to box height, or 27px */

  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;

  color: #0ef1db;

  cursor: pointer !important;

  @include responsive(963px) {
    top: 77px;
    right: auto;
    left: 130px;
    z-index: 999;
  }

  @include responsive($mobileL) {
    top: auto;
    bottom: -20px;
  }

  &-2 {
    top: 3px;

    @include responsive(963px) {
      top: 77px;
    }

    @include responsive($mobileL) {
      top: 235.1px;
    }
  }
}

.utepTrash {
  position: absolute;
  width: 56px;
  height: 56px;
  // left: 344px;
  float: right;
  right: 0;
  top: -16px;
  bottom: 0;

  // background: #1d2025;
  border-radius: 10px;

  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  /* identical to box height, or 27px */

  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;

  color: #0ef1db;

  cursor: pointer !important;

  @include responsive(963px) {
    top: 77px;
    left: 130px;
  }

  @include responsive($mobileL) {
    top: 158px;
  }

  &-2 {
    top: 3px;
    right: -63px;
    @include responsive(963px) {
      top: 76px;
    }

    @include responsive($mobileL) {
      top: auto;
      bottom: -10px;
    }
  }
}

.utepHackyInfo {
  position: absolute;
  right: 82px;
  bottom: 21px;

  z-index: 999;

  cursor: pointer;

  @include responsive($dSmall) {
    right: auto;
    left: 753px;
  }

  @include responsive(963px) {
    bottom: 1px;
    left: 75px;
  }

  @include responsive($mobileL) {
    bottom: -23px;
    top: auto;
  }

  &-2 {
    bottom: 13px;

    @include responsive($mobileL) {
      bottom: -17px;
    }
  }
}

.utepPercent {
  position: absolute;
  right: 120px;
  bottom: 16px;

  z-index: 999;

  color: white;

  font-family: Inter-Medium;

  @include responsive($dSmall) {
    right: auto;
    left: 730px;
  }

  @include responsive(963px) {
    bottom: -5px;
    left: 52px;
  }

  @include responsive($mobileL) {
    bottom: -30px;
  }

  &-2 {
    bottom: 8px;

    @include responsive($mobileL) {
      bottom: -23px;
    }
  }
}

.utepSpecialPercentInput {
  text-align: right;
  padding-right: 66px;
  overflow: visible;

  transition: none !important;
}

.utepContributorsOuterWrapper {
  position: relative;
  width: 100%;
  height: 56px;
  top: 165px;

  @include responsive(963px) {
    z-index: auto;
    z-index: 0;
    margin-left: 2.5vw;
  }

  @include responsive($mobileL) {
    margin-left: 0;
  }
}

.utepContributorsWrapper {
  position: relative;
  width: 55%;
  margin-bottom: 33px;

  @include responsive(963px) {
    line-height: 70px;
    top: 70px;
    margin-bottom: 10px;
  }

  @include responsive($mobileL) {
    margin-bottom: 80px;
  }
}

.utiuc4 {
  margin-top: 7px;
}

.utepContributorsSelectGroup {
  width: 139px;
  display: inline-flex;
  position: relative;
  margin-right: 14px;

  top: 3px;

  @include responsive($mobileL) {
    width: 100%;

    div.ruslanSelect__control {
      width: 100%;
    }
  }
}

.utepContributorsNameInputGroup {
  position: relative;
  display: inline-flex;

  width: 249px;
  //   height: 56px;

  background: #1d2025;
  border-radius: 10px;

  margin-right: 14px;

  @include responsive(963px) {
    width: 33vw;
  }

  @include responsive($mobileL) {
    width: 100%;
  }
}

.utepContributorsEmailInputGroup {
  position: relative;
  display: inline-flex;

  width: 249px;
  //   height: 56px;

  background: #1d2025;
  border-radius: 10px;

  margin-right: 14px;

  @include responsive(963px) {
    width: 32vw;
  }

  @include responsive(860px) {
    width: 31vw;
  }

  @include responsive($mobileL) {
    width: 100%;
  }
}

.utepContributorsShareInput {
  position: relative;
  display: inline-flex;
  width: 115px;

  @include responsive($mobileL) {
    top: 18px;
  }
}

.utepExpandableFieldWrapper {
  display: inline-flex;

  @include responsive($mobileL) {
    display: block;
  }
}

.utepHr2 {
  position: relative;
  width: 864px;
  height: 0px;
  top: 225px;

  z-index: 0;

  opacity: 0.4;
  border: 1px solid #151618;

  @include responsive($dSmall) {
    width: 100%;
  }

  @include responsive(963px) {
    top: 350px;
  }

  @include responsive($mobileL) {
    margin-top: 160px;
  }
}

.utepFormBelowHr {
  min-height: 1000px;
  z-index: -200;

  @include responsive(963px) {
    margin-top: 140px;
  }
}

$pushDown: 23px;

.utepPrimaryGenreWrapper {
  position: relative;
  width: 400px;
  height: 56px;
  top: 225px + $pushDown;

  @include responsive(963px) {
    width: 47.5%;
  }

  @include responsive($mobileL) {
    width: 100%;
  }
}

.utepSecondaryGenreWrapper {
  position: relative;
  width: 400px;
  height: 56px;
  top: 169px + $pushDown;
  left: 465px;

  @include responsive(963px) {
    width: 50%;
    left: 50%;
  }

  @include responsive($mobileL) {
    left: 0;
    margin-top: 90px;
    width: 100%;
  }
}

.utepGenreInfo {
  z-index: 9999;
}

.utepLanguageWrapper {
  position: relative;
  width: 400px;
  height: 56px;
  top: 205px + $pushDown;

  @include responsive(963px) {
    width: 47.5%;
  }

  @include responsive($mobileL) {
    width: 100%;
  }
}

.utepExplicitLyricsWrapper {
  position: relative;
  width: 400px;
  height: 56px;
  top: 166px + $pushDown;
  left: 465px;

  @include responsive(963px) {
    width: 50%;
    left: 50%;
  }

  @include responsive($mobileL) {
    left: 0;
    margin-top: 80px;
    width: 100%;
  }
}

.utepExplicitLyricsText {
  top: 4px;
}

.utepExplicitLyricsSwitch {
  top: -8px;
}

.utepIsrcCodeWrapper {
  position: relative;
  width: 400px;
  height: 56px;
  top: 184px + $pushDown;

  @include responsive(963px) {
    width: 47.5%;
  }

  @include responsive($mobileL) {
    width: 100%;
  }
}

.utepIswcCodeWrapper {
  position: relative;
  width: 400px;
  height: 56px;
  top: 93px + $pushDown;
  left: 465px;

  @include responsive(963px) {
    width: 50%;
    left: 50%;
  }

  @include responsive($mobileL) {
    left: 0;
    margin-top: 120px;
    width: 100%;
  }
}

.utepIsrcCodeCheckbox {
  position: relative;
  width: 18px;
  height: 18px;
  left: 1px;
  top: 194px + $pushDown;
}

.utepIsrcCodeCheckboxText {
  position: relative;
  width: 380px;
  height: 16px;
  left: 25px;
  top: 178px + $pushDown;
}

.utepPublishingRightsWrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;

  position: relative;
  width: 400px;
  height: 56px;
  top: 150px + $pushDown;

  @include responsive(963px) {
    width: 47.5%;
  }

  @include responsive($mobileL) {
    width: 100%;
    margin-top: -20px;
  }
}

.utiuc9 {
  left: 75px;

  @include responsive($mobileL) {
    left: 81px;
  }
}

.utepHr3 {
  position: relative;
  width: 864px;
  height: 0px;
  top: 215px;

  z-index: 0;

  opacity: 0.4;
  border: 1px solid #151618;

  @include responsive($dSmall) {
    width: 100%;
  }

  @include responsive(963px) {
    top: 215px;
  }
}

.utepLyricsWrapper {
  position: relative;
  width: 400px;
  height: 194px;
  top: 230px + $pushDown;

  @include responsive(963px) {
    width: 47.5%;
  }

  @include responsive($mobileL) {
    width: 100%;
  }
}

.utepLyricsBox {
  padding-top: 23px;
  // font-family: Inter-Medium !important;
  color: white !important;
}

.utepLyricsGroup {
  height: 270px;
}

.utepDeleteTrack {
  position: relative;
  float: right;
  right: 250px;
  top: 50px + $pushDown;

  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  // text-decoration-line: underline;

  color: #0ef1db;

  cursor: pointer;

  @include responsive($dSmall) {
    left: 53%;
    right: auto;
    float: left;
  }

  @include responsive(963px) {
    float: left;
    right: auto;
    left: 53%;
  }

  @include responsive($mobileL) {
    top: 285px;
    left: auto;
    right: 10px;
    float: right;
  }

  @include responsive($mobile) {
    top: 320px;
    float: left;
    right: auto;
    left: 0;
  }
}

.utepOriginalFileName {
  position: relative;
  float: left;
  left: 470px;
  top: 42px + $pushDown;

  font-family: Inter-Medium;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  // text-decoration-line: underline;

  color: white;
  opacity: 0.5;

  @include responsive(963px) {
    left: 53%;
  }

  @include responsive($mobileL) {
    top: 310px;
    left: 0;
  }

  &-fileName {
    color: white;
    opacity: 1;
    position: relative;
    float: left;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    left: 470px;
    top: 42px + $pushDown;

    @include responsive(963px) {
      left: 53%;
    }

    @include responsive($mobileL) {
      top: 310px;
      left: 0;
    }
  }
}

.utepLicenseFiles {
  width: 45%;
  height: 200px;
  top: 105px;
  position: relative;
  left: 455px;

  @include responsive($dSmall) {
    left: 53%;
  }

  @include responsive($mobileL) {
    left: auto;
    top: 350px;
    position: relative;
    width: 100%;
  }

  &-hr {
    z-index: 0;
    opacity: 0.4;
    border: 1px solid #151618;
    margin-top: 10px;
    line-height: 0;
  }
  &-headerText {
    position: relative;
    bottom: 20px;
    margin-left: 15px;
    color: white;
    font-size: 13px;

    line-height: 0;

    &-link {
      margin-left: 15px;
      color: #0ef1db;
      opacity: 0.7;
      cursor: pointer;
      position: relative;
      bottom: 4px;
    }
  }
  &-uploadFieldsWrapper {
    margin-top: 15px;
    margin-left: 10px;
    color: white;
    position: relative;
    bottom: 30px;
  }
  &-btn {
    background-color: $primaryColor;
    border: none;
    margin-top: 5px;
    border-radius: 8px;
    padding: 5px 7px 5px 7px;
    font-size: 15px;
    width: 92px;
  }
  &-err {
    color: red;
    font-size: 12px;
  }
}

.utBottomValFeedback {
  color: #f04467;
  font-size: 13px;
  margin-bottom: 10px;
}

.utFileSizeError {
  text-align: center;
  font-size: 1rem;
  line-height: 1.5rem;
  position: absolute;
  top: 230px;
  @include center;
  width: 120%;
  color: #f04467;

  @include responsive($mobileL) {
    font-size: 0.7rem;
    width: 90%;
  }

  @include responsive(544px) {
    font-size: 0.5rem;
    line-height: 1rem;
    top: 240px;
  }

  @include responsive($mobile) {
    color: white;
    background-color: #f04467;
    top: 255px;
  }
}

.utConfirmLicenses {
  position: relative;
  bottom: 3px;
  color: white;
  font-size: 13px;
  cursor: pointer;
}

.utSaveAndPreviewButton {
  position: absolute;
  width: 400px;
  height: 56px;
  // left: 289px;
  // top: 50px;

  background: #0ef1db;
  border-radius: 10px;

  margin-top: 15px;

  font-family: Inter-Medium;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 134.52%;
  /* or 24px */

  text-align: center;

  color: #151619;

  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;

  cursor: pointer;

  @include responsive($mobile) {
    width: 100%;
  }
}

.utTerms {
  position: relative;
  left: 0;
  top: 90px;
}

textarea.utepLyricsBox {
  resize: none;
}

.utDndArrow {
  float: right;
  right: 25px;
  margin-top: 8px;
  width: 12px;
  position: absolute;
}

.utDndArrowUp {
  transform: rotate(180deg); // can try with animation later
}

/// VALIDATION

.utValTextBox {
  margin-left: 5px !important;
  //   margin-top: -23px;
  color: #f04467;

  &-primary {
    &-name {
      top: -43px;

      @include responsive($mobileL) {
        top: -54px !important;
      }
    }
    &-percent {
      position: absolute !important;
      float: right;
      right: 0;

      @include responsive($dSmall) {
        right: auto;
        left: 440px;
      }

      @include responsive(963px) {
        left: 0;
        top: 112px;
      }

      @include responsive($mobileL) {
        top: 200px;
      }
    }

    &-email {
      left: 327px;

      @include responsive(963px) {
        top: 44px;
        left: 45vw;
      }

      @include responsive($mobileL) {
        top: 110px;
        left: 0;
      }
    }
  }

  &-contr {
    bottom: -24px;

    &-email {
      left: 327px;

      @include responsive(963px) {
        top: 44px;
        left: 50vw;
      }

      @include responsive($mobileL) {
        left: 0;
        width: 100%;
        height: 20px;
        top: 180px;
      }
    }

    &-percent {
      position: absolute !important;
      float: right;
      right: 0;

      @include responsive($dSmall) {
        right: auto;
        left: 440px;
      }

      @include responsive(963px) {
        left: 0;
        top: 106px;
      }

      @include responsive($mobileL) {
        left: 0;
        width: 100%;
        height: 20px;
        top: 270px;
      }
    }
  }
}

.isrcValTextBox {
  top: 29px;
}

// no arrows on number field

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
