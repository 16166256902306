@import "../../utils.scss";

a {
  text-decoration: none;
}

.hr {
  border: 1px solid $color-dark-two;
}

.social-up {
  color: #0ef1db !important;
}

.social-down {
  color: #ff3a81 !important;
}

.cur-pointer {
  cursor: pointer !important;
}

.cur-not-allowed {
  cursor: not-allowed !important;
}

.artist-dashboard {
  position: relative;
  background-color: $color-dark-two;

  &-logoMobile {
    display: none;
    @include responsive($mobileL) {
      width: 90px;
      top: 10px;
      display: block;
      position: relative;
      @include center;
    }
  }
}

.sidebar {
  width: 227px;
  height: 100vh;
  background-color: $color-dark-one;
  overflow-x: hidden;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;

  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 0.4rem;
  }
  &::-webkit-scrollbar-track {
    background-color: $color-dark-one;
  }

  @include responsive($mobileL) {
    display: none;
  }

  &-logo {
    position: relative;
    width: 100%;
  }

  &-title {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 134.52%;
    color: #ffffff;
    margin: 2.5rem;

    // background: -webkit-linear-gradient(45deg, #20dacf 1%, #ff3a81);
    // background: -o-linear-gradient(45deg, #20dacf 1%, #ff3a81);
    // background: -moz-linear-gradient(45deg, #20dacf 1%, #ff3a81);
    // background: linear-gradient(45deg, #20dacf 1%, #ff3a81);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  &-menu {
    margin-bottom: 2.625rem;
    &-header {
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 134.52%;
      color: #ffffff;
      padding-left: 1.25rem;
      img {
        margin-right: 0.625rem;
        width: 18px;
        height: 18px;
      }
    }
    ul {
      padding-left: 0;
      margin: 0;
      li {
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 134.52%;
        color: $color-light-two;
        list-style-type: none;
        padding: 0.5rem 0 0.5rem 3.25rem;
        cursor: pointer;

        &.active {
          border-left: 6px solid $primary;
          color: $primary;
          padding-left: 2.875rem !important;
          transition: all 0.2s;

          &:hover {
            background-color: $color-light-one;
            color: $primary;
            text-decoration: none;
          }
        }
        &:hover {
          color: $primary;
          background-color: $color-light-one;
        }
      }
    }
  }

  &-invite {
    margin: 0 0.75rem;
    background-color: $color-light-one;
    border-radius: 10px;
    padding: 2.5rem 10px 12px;
    position: absolute;
    bottom: 14px;

    &-emoji {
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 32px;
      line-height: 134.52%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &-title {
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 134.52%;
      text-align: center;
      color: #ffffff;
      margin-top: 1rem;
    }

    &-subtitle {
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 134.52%;
      text-align: center;
      color: $color-light-two;
    }

    &-button {
      background: $color-dark-one;
      border-radius: 10px;
      button {
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 134.52%;
        background-color: $color-dark-one;
        color: #0ef1db;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        &:hover {
          background-color: $color-dark-one;
          color: #0ef1db;
        }
      }
    }

    &-star {
    }
  }
}

.mobileMenu {
  position: relative;
  width: 100%;
  top: 80px;
  display: grid;
  left: -10vw;

  justify-content: center;

  &-header {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 7vw;
    line-height: 134.52%;
    color: #ffffff;
    padding-left: 2.5rem;
    img {
      margin-right: 1.2rem;
      // width: 18px;
      // height: 18px;
      width: 7vw;
      height: 7vw;
    }
  }

  li {
    display: block;
    color: $color-light-two;
    font-size: 7vw;

    &:active {
      color: $primary;
    }
  }

  &-burger {
    display: none;
    z-index: 9998;

    @include responsive($mobileL) {
      position: absolute;
      top: 8vw;
      right: 9vw;
      display: inline-block;
      cursor: pointer;
    }
  }

  &-overlay {
    background-color: $color-dark-one;
    position: absolute;
    width: 100vw;
    height: 100%;
    min-height: 215vh;
    top: 0;
    left: 0;
    z-index: 9999;
  }

  &-x {
    position: relative;
    float: right;
    top: 12vw;
    right: 12vw;

    cursor: pointer;
  }
}

.dashboard-overview {
  margin-left: 227px;
  padding: 1.5rem 1.5rem;
  display: grid;
  row-gap: 1.5rem;
  @include responsive($mobileL) {
    margin-left: 0;
  }
  &-header {
    margin-left: 227px;

    padding: 3.5rem 13px 0 1.5rem;
    display: grid;
    justify-content: space-between;
    grid-template-columns: 99%;
    row-gap: 20px;

    @include responsive($mobileL) {
      padding-top: 25px;
      margin-left: 0;
    }

    .dspInput,
    .dspInput::placeholder {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 134.52%;
      color: $color-light-two;
    }
    &-top {
      display: grid;
      grid-template-columns: 1fr 0fr;
      @include responsive($mobileL) {
        margin-top: 20px;
      }
      &-left {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;

        &-artists-chips {
          display: flex;
          flex-direction: row-reverse;
          column-gap: 1rem;
          align-self: flex-end;

          white-space: nowrap;
          overflow: hidden;

          flex-wrap: wrap;
          justify-content: flex-start;
          flex-flow: wrap-reverse;

          &::-webkit-scrollbar {
            height: 5px;
          }
          &::-webkit-scrollbar-track {
            background: rgba(255, 255, 255, 0.2) !important;
          }
        }
      }
      &-right {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-right: 10px;
        .user-profile {
          @include responsive($mobileL) {
            display: none;
          }
        }
      }
    }
    &-search {
      &-input {
        position: relative;
        // width: 300px;
        height: 56px;
        float: right;
        border: none !important;
        border-radius: 10px;
        box-shadow: none;
        .MuiPaper-root {
          background-color: transparent;
          box-shadow: none;
        }
        .react-select__control {
          background: $color-dark-one;
          border-radius: 10px;
          padding: 0.625rem;
          font-size: 1em;
        }
        .react-select__option {
          font-size: 1em;
          @include responsive($mobileL) {
            font-size: 0.8em;
          }
        }
        .react-select__menu {
          z-index: 9999 !important;
        }
      }
      &-checkboxesWrapper {
        display: inline-flex;
        // width: 40%;
      }
      &-checkboxesLabel {
        color: white;
      }
      &-item {
        display: flex;
        color: white;
        flex-wrap: wrap;
        align-items: center;
        cursor: pointer;
        margin-left: calc(min(1.2vw, 15px));
        &-checkbox {
          margin-left: calc(min(1.2vw, 5px));
          margin-right: calc(min(1.2vw, 5px));
        }
      }
    }
  }

  &-totalfans {
    padding: 1.5rem;
    background: $color-dark-one;
    border-radius: 10px;
    display: grid;
    @include responsive($mobileL) {
      display: block;
    }
    grid-template-columns: 1fr 1fr;
    min-height: 372px;

    &-spinner {
      height: 80%;
      margin-top: 1%;
      &-wrapper {
        width: 200%;
        height: 100%;
        text-align: center;
      }
    }

    &-left {
      &-stats {
        display: grid;
        label {
          font-family: Inter;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 134.52%;
          color: $color-light-two;
        }

        &-follower {
          &-count {
            font-family: Inter;
            font-style: normal;
            font-weight: 500;
            font-size: 32px;
            line-height: 134.52%;
            color: #ffffff;
          }
          &-time {
            margin: 0 0 0 0.5rem;
            label {
              font-family: Inter;
              font-style: normal;
              font-weight: normal;
              font-size: 11px;
              line-height: 13px;
              margin: 0 0 0 0.25rem;
            }
          }
        }
      }
      &-cards {
        display: flex;
        align-content: flex-start;
        flex-wrap: wrap;
        gap: 0.75rem;
        margin-top: 1.5rem;
        @include responsive($mobileL) {
          margin-top: 0.5rem;
        }
      }
    }

    &-chart {
      &-wrapper {
        text-align: center;
        background-color: $color-light-one;
        border-radius: 10px;
        padding: 0 1.5rem;
        margin: auto;
        height: 100%;

        @include responsive($mobileL) {
          display: none;
        }
      }
    }
  }

  &-metadata {
    padding: 1rem 1.5rem 1rem 1.5rem;
    background: #1d2025;
    border-radius: 10px;
    overflow-x: auto;
  }

  &-streaming-stats {
    padding: 1.5rem;
    background: $color-dark-one;
    border-radius: 10px;
    overflow-x: auto;

    &-spinner {
      &-wrapper {
        width: 100%;
        height: 100%;
        text-align: center;
      }
    }

    &-header {
      display: flex;
      justify-content: space-between;
      &-left {
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 134.52%;
        color: $color-light-two;
        white-space: nowrap;
      }
      &-emoji {
        margin-left: 3px;
      }
    }

    &-cards {
      display: flex;
      align-content: flex-start;
      flex-wrap: wrap;
      gap: 0.75rem;
      margin-top: 1.5rem;
      @include responsive($mobileL) {
        margin-top: 0.5rem;
      }
      overflow-x: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
      &-card {
        // width: 190px !important;
      }
    }
    &-more-details {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-top: 3rem;
      column-gap: 1.5rem;
      @include responsive($mobileL) {
        margin-top: 2rem;
        justify-content: center;
      }
      &-info {
        @include responsive($mobileL) {
          display: none;
        }
        label {
          font-family: Inter;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 134.52%;
          color: $color-light-two;
        }
      }

      &-button {
        width: 255px;
        background: #0ef1db;
        border-radius: 10px;
      }
    }

    &-chart {
      border-radius: 10px;
      margin-top: 19px;
      label {
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 11px;
        line-height: 134.52%;
        color: $color-light-two;
      }
    }
  }

  &-social-stats {
    padding: 1.5rem;
    background: $color-dark-one;
    border-radius: 10px;
    overflow-x: auto;

    &-spinner {
      &-wrapper {
        width: 100%;
        height: 100%;
        text-align: center;
      }
    }

    &-header {
      display: flex;
      justify-content: space-between;
      &-left {
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 134.52%;
        color: $color-light-two;
      }
      &-emoji {
        margin-left: 3px;
      }
    }
    &-cards {
      display: flex;
      align-content: flex-start;
      flex-wrap: wrap;
      gap: 0.75rem;
      margin-top: 1.5rem;
      @include responsive($mobileL) {
        margin-top: 0.5rem;
      }
      overflow-x: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
      &-card {
        // min-width: 206px !important;
      }
    }
    &-more-details {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-top: 3rem;
      column-gap: 1.5rem;
      @include responsive($mobileL) {
        margin-top: 2rem;
        justify-content: center;
      }
      &-info {
        @include responsive($mobileL) {
          display: none;
        }
        label {
          font-family: Inter;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 134.52%;
          color: $color-light-two;
        }
      }

      &-button {
        width: 255px;
        background: #0ef1db;
        border-radius: 10px;
      }
    }
  }

  &-your-top-playlists {
    padding: 1.5rem;
    @include responsive($mobileL) {
      padding: 0.5rem;
      padding-top: 1rem;
    }
    background: $color-dark-one;
    border-radius: 10px;
    // font-size: calc(10px + 2vmin);
    &-header {
      display: flex;
      justify-content: space-between;
      &-left {
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 134.52%;
        color: $color-light-two;
      }
      &-emoji {
        margin-left: 3px;
      }
    }
    &-social {
      margin-top: 2.5rem;
      &-name {
        label {
          margin: 0 0 0 0.5rem;
          font-family: Inter;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 134.52%;
          color: $color-light-two;
        }
      }
      &-cards {
        padding: 1rem;
        &-nothing {
          display: flex;
          justify-content: center;
          align-items: center;
          label {
            font-family: Inter;
            font-style: normal;
            font-weight: normal;
            font-size: 20px;
            line-height: 134.52%;
            color: #ffffff;
          }
          &-emoji {
            margin-left: 5px;
          }
        }
        // .itemWrapper {
        //   padding: 1rem !important;
        // }

        .item {
          background-color: $color-light-one;
          border-radius: 10px;
        }
        .leftPane {
          padding-left: 0 !important;
        }
      }
    }
    &-more-details {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-top: 3rem;
      column-gap: 1.5rem;

      @include responsive($mobileL) {
        margin-top: 1rem;
        margin-bottom: 2rem;
      }

      &-button {
        width: 255px;
        background: #0ef1db;
        border-radius: 10px;
      }
    }
  }

  &-followed-artists {
    padding: 1.5rem;
    background: $color-dark-one;
    border-radius: 10px;
    &-header {
      display: flex;
      justify-content: space-between;
      &-left {
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 134.52%;
        color: $color-light-two;
      }
      &-emoji {
        margin-left: 3px;
      }
    }
    &-content {
      text-align: center;
      &-title {
        label {
          font-family: Inter;
          font-style: normal;
          font-weight: normal;
          font-size: 20px;
          line-height: 134.52%;
          color: #ffffff;
        }
        &-emoji {
          margin-left: 5px;
        }
      }
      &-search {
        width: 326px;
        margin: 1rem auto 4.625rem auto;
        background: $color-dark-two;
        opacity: 0.5;
        border-radius: 10px;
        box-shadow: none;
        span.dspInputText,
        input.dspInput.form-control {
          background-color: $color-dark-two;
        }
      }
    }
  }

  &-suggested-artists {
    background: $color-dark-one;
    border-radius: 10px;
  }

  &-feedback {
    display: grid;
    grid-template-columns: 2fr 3fr;
  }

  &-track-features {
    padding: 1.5rem;
    background: $color-dark-one;
    border-radius: 10px;
    @include responsive($mobileL) {
      display: none;
    }
    &-wrapper-mobile {
      display: none;
      padding: 1.5rem;
      background: $color-dark-one;
      border-radius: 10px;
      @include responsive($mobileL) {
        display: block;
      }
    }
  }

  &-popularity {
    padding: 1.5rem;
    background: $color-dark-one;
    border-radius: 10px;
  }
}

.your-earnings {
  width: 270px;
  padding: 1rem 1.5rem;
  background: $color-dark-one;
  border-radius: 10px;

  &-header {
    display: flex;
    justify-content: space-between;
    &-title {
      label {
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 134.52%;
        color: $color-light-two;
      }
    }
    &-emoji {
      margin-left: 3px;
    }
    &-more {
      cursor: pointer;
    }
  }

  &-body {
    display: inline-flex;
    column-gap: 0.5rem;
    &-amount {
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 32px;
      line-height: 134.52%;
      color: #ffffff;
    }

    &-release {
      display: grid;
      &-num {
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 134.52%;
        display: flex;
        align-items: flex-end;
        color: #ffffff;
      }
      &-stat {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        line-height: 13px;
        color: #0ef1db;
        &-label {
          margin: 0 0 0 0.25rem;
        }
      }
    }
  }
}

.user-profile {
  display: inline-flex;
  align-items: center;
  margin-bottom: 1rem;
  position: relative;

  @include responsive($mobileL) {
    display: inline-block;
    right: 8vw;
  }

  @include responsive($mobile) {
    @include center;
    bottom: 30px;
  }

  &-name {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 134.52%;
    display: flex;
    align-items: center;
    color: #ffffff;

    @include responsive($mobileL) {
      display: none;
    }

    @include responsive($mobile) {
      display: none;
    }
  }
  &-avatar {
    width: 42px;
    height: 42px;
    border-radius: 50%;
    margin-left: 1rem;
    cursor: pointer;

    &-desktop {
      @include responsive($mobileL) {
        display: none;
      }
    }

    &-mobile {
      display: none;
    }
  }
  &-caret {
    margin-left: 0.5rem;
    cursor: pointer;
    -webkit-transition-duration: 0.5s;
    -moz-transition-duration: 0.5s;
    -o-transition-duration: 0.5s;
    transition-duration: 0.5s;

    -webkit-transition-property: -webkit-transform;
    -moz-transition-property: -moz-transform;
    -o-transition-property: -o-transform;
    transition-property: transform;
    &.up {
      transform: rotate(180deg);
    }

    @include responsive($mobileL) {
      display: none;
    }
  }
  &-dropdown {
    position: absolute;
    width: 200px;
    float: right;
    right: 0;
    top: 50px;
    z-index: 5;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.5s linear;

    @include responsive($mobileL) {
      display: none;
    }

    &.show {
      visibility: visible;
      opacity: 1;
    }
    ul {
      list-style-type: none;
      text-align: right;
      padding: 0;
      margin: 0;
      li {
        background-color: $color-dark-one;
        border-left: solid 1px#0ef1db;
        border-right: solid 1px#0ef1db;
        &:first-child {
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
          border-top: solid 1px#0ef1db;
        }
        &:last-child {
          border-bottom-left-radius: 8px;
          border-bottom-right-radius: 8px;
          border-bottom: solid 1px#0ef1db;
        }
        &:hover {
          background-color: #0ef1dabf;
        }
        a {
          // color: $color-dark-one;
          color: white;
          font-family: Inter;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 134.52%;
        }
      }
    }

    &-inner {
      padding: 0.25rem 1rem;
    }
  }
}

.social-card {
  width: 190px;
  background: $color-dark-two;
  border-radius: 10px;
  padding: 0.75rem;
  display: grid;
  position: relative;

  @include responsive($mobileL) {
    width: 100%;
  }

  &-name {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    z-index: 100;

    &-white {
      color: white !important;
    }

    label {
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 134.52%;
      color: $color-light-two;
      margin: 0;
    }
  }
  &-follower {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 134.52%;
    color: #ffffff;
    z-index: 100;
  }

  &-stat {
    display: flex;
    align-items: baseline;
    z-index: 100;
    &-label {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 11px;
      line-height: 13px;
      margin: 0 0 0 0.25rem;
      z-index: 100;
    }

    &-comingSoon {
      color: white;
      opacity: 0.5;
      font-size: 14px;
      z-index: 100;
    }
  }
  &-not-connected {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 134.52%;
    color: $primary;
    text-decoration: underline;
    margin-bottom: 0;
    cursor: pointer;
    z-index: 100;
  }

  &-bar {
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 10px;
    opacity: 0.9;

    &-mobile {
      display: none;
      @include responsive($mobileL) {
        display: block;
      }
    }
  }
}

.social-icon {
  width: 36px;
  height: 36px;
  background: $color-dark-one;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  img {
    max-width: 60%;
    height: auto;
  }
}

.horizontal-scroll-button-group {
  @include responsive($mobileL) {
    display: none;
  }
  span {
    padding: 1rem;
    cursor: pointer;
    user-select: none;
    &.disable {
      pointer-events: none;
    }
  }
  &-caret {
    width: 14px;
    height: 7px;
    &.left {
      transform: rotate(90deg);
    }
    &.right {
      transform: rotate(270deg);
    }
    &.disable {
      opacity: 0.25;
    }
  }
}

.graph {
  &-tooltip {
    &-service {
      position: absolute;
      bottom: 4px;
      right: 6px;

      &-apple {
        color: #a157ff;
      }
      &-spotify {
        color: #1ed760;
      }
    }
  }
}
