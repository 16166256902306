$mobile: 480px;
$mobileL: 768px;

@mixin responsive($query) {
  @media (max-width: + $query) {
    @content;
  }
}

@mixin center() {
  left: 50%;
  transform: translate(-50%, 0);
}

.distributionTitle {
  position: absolute;
  width: 780px;
  height: 172px;
  top: 188px;

  @include center;

  font-family: Inter-Bold;
  font-style: normal;
  font-weight: bold;
  font-size: 64px;
  line-height: 134.52%;
  /* or 86px */

  text-align: center;

  color: #ffffff;

  .gradientTitleWords {
    background: linear-gradient(81.02deg, #20dacf 20.3%, #ff3a81 68.53%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  @include responsive($mobile) {
    width: 100vw;
    left: auto;
    font-size: 28px;
    top: 200px;
  }
}

.distributionSubTitle {
  position: absolute;
  width: 656px;
  height: 64px;
  top: 392px;

  @include center;

  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 134.52%;
  /* or 32px */

  text-align: center;

  color: #ffffff;

  @include responsive($mobile) {
    width: 100vw;
    font-size: 16px;
    font-family: Inter-Light;

    padding-left: 42px;
    padding-right: 42px;

    top: 295px;
    left: auto;
  }
}

.myReleases {
  &-overview {
    margin-left: 227px;
    padding: 3.5rem 1.5rem;
    display: grid;
    row-gap: 1.5rem;

    @include responsive($mobileL) {
      display: block;
      padding: 3.5rem 1.5rem;
      margin-left: 0;
    }
  }
  &-header {
    height: 200px;

    &-right {
      position: relative;
      float: right;
      right: 32px;

      @include responsive($mobile) {
        width: 100%;
        right: 0;
      }
    }
  }
}

.distributionButton {
  position: relative;
  width: 255px;
  height: 56px;
  top: 60px;

  @include center;

  background: #0ef1db;
  border-radius: 10px;

  color: #151619;

  font-family: Inter-SemiBold;

  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  z-index: 990;

  @include responsive($mobileL) {
    top: 100px;
  }

  @include responsive($mobile) {
    top: 70px;
    @include center;
  }
}

.distributionGuidelinesNote {
  display: block !important;
  position: relative;
  // width: 255px;
  text-align: center;
  height: 56px;
  top: 60px;

  @include center;

  // background: #0ef1db;
  border-radius: 10px;

  color: white;

  font-family: Inter-SemiBold;

  cursor: pointer;

  align-items: center;
  justify-content: center;
  text-align: center;

  z-index: 990;

  @include responsive($mobileL) {
    top: 110px;
    width: auto;
  }

  @include responsive($mobile) {
    top: 80px;
    width: auto;
    @include center;
  }
}

.dDecoration1 {
  position: absolute;
  width: 110px;
  height: 110px;
  margin-left: 5%;
  top: 70px;

  border: 4px dashed #2c3036;
  box-sizing: border-box;
  border-radius: 16px;
  transform: rotate(-16.75deg);

  padding: 29px 0 0 41px;

  .dd1a {
    position: relative;
    width: 22px;
    height: 43px;
    // left: 250.13px;
    // top: 479.1px;

    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 134.52%;
    /* identical to box height, or 43px */

    color: #0ef1db;

    transform: rotate(-1deg);
  }

  @include responsive($mobile) {
    display: none;
  }
}

.form-control:disabled {
  background: #1d2025;
  opacity: 0.5;

  &:hover {
    border: none;
  }
}

.dShader {
  position: absolute;
  width: 100%;
  height: 280px;
  top: 0px;

  margin-left: -20px;

  z-index: 900;

  background: linear-gradient(0deg, #343947 -12%, rgba(52, 57, 71, 0) 88%);

  @include responsive($mobileL) {
    top: -25px;
  }

  @include responsive($mobile) {
    // width: 100vw;
    // height: 179px;
    // left: -40px;
    // top: 536px;
  }
}

.dDecoration2 {
  position: absolute;
  width: 236px;
  height: 141px;
  float: right;
  // left: 23%;
  right: 40px;
  // top: -30px;

  display: none;

  background: #1d2025;
  border-radius: 16px;
  transform: rotate(-3.43deg);

  font-size: 12px;

  @include responsive($mobile) {
    width: 236px;
    height: 141px;
    left: -40px;
    top: 548.14px;
  }

  .dd2Text {
    position: relative;
    top: 20px;
    left: 15px;

    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 134.52%;
    /* or 32px */

    display: flex;
    align-items: center;
    text-align: center;

    color: #ffffff;
  }

  .dd2badge1 {
    position: absolute;

    padding: 5px 0 0 20px;

    width: 112px;
    height: 30px;

    top: 60px;
    left: 15px;

    color: white;

    border: 1px solid #606266;
    box-sizing: border-box;
    border-radius: 32px;
  }

  .dd2badge2 {
    width: 70px;
    height: 30px;

    background: #0ef1db;
    border-radius: 32px;

    color: #151619;

    position: absolute;

    padding: 6px 0 0 15px;

    top: 60px;
    right: 25px;
  }

  .dd2badge3 {
    bottom: 12px;
    left: 15px;

    width: 86px;
    height: 30px;

    position: absolute;

    color: white;

    padding: 5px 0 0 14px;

    border: 1px solid #606266;
    box-sizing: border-box;
    border-radius: 32px;
  }

  .dd2badge4 {
    border: 1px solid #606266;
    box-sizing: border-box;
    border-radius: 32px;

    position: absolute;

    bottom: 12px;
    left: 110px;

    width: 31px;
    height: 30px;
  }
}

.dDecoration3 {
  position: absolute;
  width: 146px;
  height: 205px;
  left: 33%;
  // left: 65%;
  top: 30px;

  background: #1d2025;
  border-radius: 16px;
  transform: rotate(10deg);

  .dd3star {
    position: absolute;
    right: 14px;
    top: 14px;
    transform: rotate(-4.77deg);
  }

  .dd3group {
    position: absolute;
    top: 89px;
    left: 53px;
    transform: rotate(-4.77deg);
  }

  @include responsive($mobile) {
    display: none;
  }
}

.dDecoration4 {
  position: absolute;
  width: 133.97px;
  height: 133.97px;
  left: 84%;
  right: 20%;
  // top: -600px;

  z-index: 0;

  display: none;

  background: #1d2025;
  border-radius: 16px;
  transform: rotate(9.4deg);

  padding: 22px;

  @include responsive($mobile) {
    position: relative;
    width: 133.97px;
    height: 133.97px;
    left: auto;
    right: -60px;
    top: 488px;
    white-space: normal;
  }

  .dd4Inner1 {
    background: #2c3036;
    box-shadow: 0px 1px 34px rgba(0, 0, 0, 0.25);
    border-radius: 74px;
    transform: rotate(9.4deg);
    width: 88.9px;
    height: 88.9px;

    padding: 13px;
  }

  .dd4InnerSpotify {
    transform: rotate(-18deg);
    width: 63px;
  }
}

.mrReleaseCards {
  $width: 100%;

  position: relative;
  width: $width;

  margin-top: 20px;

  @include center;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;

  gap: $width / 12;

  @include responsive($mobileL) {
    top: 30px;
  }

  @include responsive($mobile) {
    gap: 20px;
    width: 100vw;
    justify-content: center;
  }
}

.admin-releases {
  padding: 16px;
  padding-bottom: 0;
  background-color: hsla(0, 0%, 100%, 0.05);
  border-radius: 10px;
}

.release-card-link {
  border-radius: 12px;
  cursor: pointer;
  &:hover {
    .rcImage {
      transform: scale(1.05);
    }
  }
}
