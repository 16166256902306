$color-dark-one: #1d2025;
$color-dark-two: #343947;
$color-light-one: #33373f;
$color-light-two: #8e8f92;

@mixin xyFlexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

$mobileL: 768px;
$mobile: 480px;

@mixin responsive($query) {
  @media (max-width: + $query) {
    @content;
  }
}

@mixin center() {
  left: 50%;
  transform: translate(-50%, 0);
}

.performPayment {
  &-main {
    height: 100%;
    display: inline-block;
  }
  &-header {
    // font-size: 14px;
    @include responsive($mobileL) {
      font-size: 17px;
      margin-bottom: 10px;
    }

    @include responsive($mobile) {
      font-size: 20px;
    }

    @include responsive(335px) {
      font-size: 5vw;
      line-height: 20px;
    }
  }
  &-sub {
    font-size: 12px;
    opacity: 0.7;
  }
  &-couponInput {
    border-left: none;

    text-align: center;

    text-transform: uppercase;

    height: 56px;
    background: #343947;
    border-radius: 10px;

    @include responsive($mobileL) {
      width: 50%;
      height: 50px;
    }

    @include responsive($mobile) {
      font-size: 10px;
    }

    &:focus,
    &:hover,
    &:visited {
      border: none;
      background: #343947;
      color: white;
    }

    &:enabled {
      border: none;
      background: #343947;
      color: white;
    }

    &-wrapper {
      width: 100%;
      display: flex;
      justify-content: center;

      @include responsive($mobileL) {
        margin-top: -10px;
      }
    }

    &-group {
      width: 50%;
      box-shadow: none;
    }

    &-upperComment {
      position: absolute;

      z-index: 99;

      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 134.52%;
      /* or 16px */

      color: #ffffff;

      opacity: 0.5;

      margin-top: 6px;
    }

    &-validation {
      position: relative;
      display: block;
      @include center;
      margin-left: auto;
      text-align: center;
      font-size: 12px;
    }
  }
  &-btn {
    color: black;
    line-height: 25px;
    padding: 20px;
    font-size: 14px;
    width: 350px;

    @include responsive($mobileL) {
      padding: 35px;
      width: auto;
      font-size: 17px;
    }

    @include responsive($mobile) {
      width: 100%;
      height: 40px;
      font-size: 15px;
      line-height: 20px;
    }

    &-wrapper {
      display: block;
      width: 350px;
      justify-content: center;
      align-items: center;

      position: relative;
      @include center;
    }

    &-spinner {
      width: 40px;
      height: 40px;
      margin-bottom: 6px;
    }
  }
  &-error {
    &-retry {
      cursor: pointer;
    }
  }
}

.editPayment {
  &-box {
    &-wrapper {
      display: flex;
      justify-content: center;
      width: 100%;
    }
    min-width: 285px;
    width: auto;
    height: 56px;
    background-color: rgba(51, 55, 63, 0.5);
    margin-bottom: 12px;
    border-radius: 10px;

    &-text {
      font-family: Inter;
      font-style: normal;
      font-size: 16px;
      line-height: 134.52%;

      color: #ffffff;

      position: relative;
      top: 18px;
      float: left;
      padding-left: 5px;
      padding-right: 15px;
    }

    &-logoBox {
      background: #1d2025;
      border-radius: 8px;

      width: 36px;
      height: 36px;

      position: relative;
      float: left;
      left: 10px;
      top: 10px;

      padding-top: 4px;
    }

    &-comment {
      position: relative;
      color: #0ef1db;
      text-decoration: underline;
      font-size: 14px;

      cursor: pointer;
    }
  }
}

.setPaymentMethod {
  display: flex;
  justify-content: center;

  &-buttonsWrapper {
    position: relative;
    width: 285px;
    height: 192px;

    // padding-top: 8%;

    @include center;
  }

  $btnWidth: 285px;

  &-button {
    width: $btnWidth;
    height: 56px;
    background-color: rgba(51, 55, 63, 0.5);
    border-radius: 10px;

    margin: auto;

    margin-bottom: 12px;

    cursor: pointer;

    &:hover {
      background-color: $color-light-two;
    }

    &-text {
      font-family: Inter;
      font-style: normal;
      font-size: 16px;
      line-height: 134.52%;

      color: #ffffff;

      position: relative;
      left: 16px;
      top: 18px;
      float: left;
    }

    &-logoBox {
      background: #1d2025;
      border-radius: 8px;

      width: 36px;
      height: 36px;

      position: relative;
      float: right;
      right: 10px;
      top: 10px;

      padding-top: 4px;
    }

    &-comment {
      font-size: 12px;
      opacity: 0.4;
    }
  }
}
