$mobile: 425px;

@mixin responsive($query) {
  @media (max-width: + $query) {
    @content;
  }
}

html {
  background: rgb(52, 58, 71);
}

.ruslanCheckbox {
  border: 2px solid #0ef1db;
  box-sizing: border-box;
  border-radius: 5px;
  cursor: pointer;

  width: 18px;
  height: 18px;

  &-red {
    span > svg {
      color: red;
      fill: red;
      padding: 0;
    }
  }
}

.ruslanCheckbox_checked {
  background: #0ef1db;
  border: 2px solid #0ef1db;
  box-sizing: border-box;
  border-radius: 5px;
}

::-moz-selection {
  /* Code for Firefox */
  // color: #0ef1db;
  background: #0ef1db;
}

::selection {
  // color: #0ef1db;
  background: #0ef1db;
  color: black;
}

.menuListText {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 134.52%;
  /* or 16px */

  width: 100%;

  color: #ffffff;

  opacity: 0.5;

  position: absolute;
  top: 30px;

  left: 50%;
  transform: translate(-50%, 0);
  text-align: center;
}

.menuListEmptyText {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 134.52%;
  /* or 16px */

  color: #ffffff;

  opacity: 0.5;

  position: absolute;
  top: 63px;

  left: 50%;
  transform: translate(-50%, 0);
  text-align: center;
}

.menuListEmptyButton {
  width: 129px;
  height: 42px;
  background: #33383f;
  border-radius: 10px;

  top: 95px;

  position: absolute;

  left: 50%;
  transform: translate(-50%, 0);
  text-align: center;

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  color: #0ef1db;
  font-family: Inter-SemiBold;
  font-size: 12px;

  cursor: pointer;
}

.disabledAddon {
  opacity: 0.5;
}

.playtreksButton {
  background: #0ef1db;
  border-radius: 10px;

  width: 400px;
  height: 56px;

  text-align: center;
  font-family: Inter-SemiBold;
  letter-spacing: normal;

  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */

  &-inactive {
    background: rgba(255, 255, 255, 0.44);
    cursor: default;
  }
}

$primaryColor: #0ef1db;

.playtreksUnderlinePrimaryText {
  color: $primaryColor;
  text-decoration: underline;
}

input:-internal-autofill-selected {
  background-color: #1d2025 !important;
}

.ptImageUploadField {
  position: relative;
  display: inline-block;
  width: 300px;
  height: 300px;

  background: #1d2025;
  border-radius: 10px;

  cursor: pointer;
}

.ptImageInput {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.ptImagePlaceholder {
  padding: 125px;
}

.ptUploadImageCircle {
  position: relative;
  width: 28px;
  height: 28px;

  bottom: 60px;
  left: 28px;

  box-sizing: border-box;

  color: #1d2025;
}

.ptImagePreview {
  width: 100%;
  position: absolute;
  top: -9999px;
  bottom: -9999px;
  left: -9999px;
  right: -9999px;
  margin: auto;
}

.ptTooltip,
.__react_component_tooltip {
  border: 1px solid #0ef1db !important;
  border-radius: 8px !important;
  font-size: 12px !important;
  line-height: 18px !important;
  z-index: 9999 !important;
}

.valTextBox {
  position: relative;
  font-size: 0.7em;
  text-align: left;
  margin-left: 50px;
  color: #f04467;
}

.ptAbsolute {
  position: absolute !important;
}
