$color-dark-one: #1d2025;
$color-dark-two: #343947;
$color-light-one: #33373f;
$color-light-two: #8e8f92;

$primary: #0ef1db;
$secondary: #a5aaa8;
$darkerbackground: #383b3a;
$background: #3d403f;

$dSmall: 1024px;
$mobileL: 768px;
$mobile: 480px;

@mixin responsive($query) {
  @media (max-width: + $query) {
    @content;
  }
}

@mixin center() {
  left: 50%;
  transform: translate(-50%, 0);
}

@mixin xyFlexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

@mixin nonSelect {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
}
