@mixin center() {
  left: 50%;
  transform: translate(-50%, 0);
}

$mobile: 480px;
$mobileL: 768px;

@mixin responsive($query) {
  @media (max-width: + $query) {
    @content;
  }
}

$primaryColor: #0ef1db;
$darkColor: #1d2025;
$lightGrey: rgba(255, 255, 255, 0.44);

.obHeader {
  height: 70px;
  width: 70vw;
  position: absolute;
  z-index: 999;
  top: 0;
  padding-top: 20px;
  text-align: center;
}

.obDecorations {
  overflow: hidden;
  img {
    position: absolute;
  }

  &-Rect1 {
    position: relative;
    top: 0;

    @include responsive($mobileL) {
      display: none;
    }

    @include responsive($mobile) {
      display: none;
    }
  }

  &-Rect2 {
    // position: relative;
    float: right;
    top: 0;
    right: 0;
    height: 700px;
    // width: 50%;

    @include responsive($mobileL) {
      display: none;
    }

    @include responsive($mobile) {
      display: none;
    }
  }

  &-Vector1 {
    position: relative;
    top: 0;
  }

  &-Vector2 {
    position: relative;
    float: right;
    right: 0;
    bottom: 0;
  }
}

.obLogoWrapper {
  position: relative;
  top: 22%;
  width: 60px;
}

.obLogo {
  width: 180px;
  cursor: pointer;
}

.obMenuItems {
  font-family: OpenSans, sans-serif;
  font-weight: 300;
  margin-left: 25px;
  font-size: 1em;
  display: inline;
}

.obMenuItem {
  color: white;
  margin-left: 45px;
}

.obHeaderButton {
  position: absolute;
  float: right;
  width: 100px;
  height: 40px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  background-image: linear-gradient(to right, #263148, #344675);
  text-align: center;
  padding: 7px;
  font-size: 0.9em;
  color: #169e91;

  img {
    width: 1em;
    margin: 0 0.7em 0.2em 0;
  }
}

.obContent {
  text-align: center;
  width: 100vw;
  height: 100vh;
}

.obFooter {
  position: fixed;
  width: 100vw;
  bottom: 0;
  left: 20px;
  font-size: 0.8em;
  color: #adb5bd;
  // padding: 0 20% 0 20%;
  @include center;
  text-align: center;
  background-color: $darkColor;

  @include responsive($mobileL) {
    position: fixed;
    width: 100vw;
    font-size: 0.58em;
  }

  @include responsive($mobile) {
    position: fixed;
    width: 80vw;
    max-width: 400px;
    max-height: 40px;
    font-size: 2.5vw;
  }
}

.obFooterRight {
  float: right;
  color: #525f7f;
}

.obMainText {
  font-family: Industry;
  font-weight: 100;
  color: white;
  position: relative;
  top: 130px;
  font-size: 1.7em;
}

.obCenterBox {
  text-align: center;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);

  &-1 {
    @include responsive($mobileL) {
      width: auto !important;
      left: 15px;
      right: 15px;
      top: 15px;
      transform: none;
    }
  }

  &-2 {
    @include responsive($mobileL) {
      width: auto !important;
      left: 15px;
      right: 15px;
      top: 15px;
      transform: none;
    }
  }

  @include responsive($mobileL) {
    width: 80%;
  }
}

.wideObModal {
  width: 500px;
}

// onboarding

.obModal {
  width: 662px;
  // height: 470px;
  border-radius: 10px;
  background-color: #1d2025;
  color: white;
  position: relative;
  top: 110px;

  text-align: center;

  @include responsive($mobile) {
    top: 50px;
  }

  &-0 {
    padding: 77px;
    width: 100%;

    @include responsive($mobileL) {
      padding: 10%;
    }
  }

  &-1 {
    min-height: 570px;

    @include responsive($mobileL) {
      width: auto;
      top: 0;
      padding: 0 40px 50px 40px;
    }

    @include responsive($mobile) {
      padding-bottom: 10px;
    }
  }

  &-2 {
    min-height: 500px;

    @include responsive($mobileL) {
      width: auto;
      top: 0;
      padding: 0 40px 50px 40px;
      min-height: 550px;
    }

    @include responsive($mobile) {
      padding-bottom: 10px;
    }
  }

  &-3 {
    min-height: 500px;

    @include responsive($mobileL) {
      width: auto;
      top: 0;
      padding: 0 40px 50px 40px;
      min-height: 550px;
      height: auto;
    }

    @include responsive($mobile) {
      padding-bottom: 10px;
    }
  }

  &-Header {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 134.52%;
    /* or 43px */

    text-align: center;

    color: #ffffff;

    @include responsive($mobileL) {
      font-size: 28px;
    }

    @include responsive($mobile) {
      font-size: 25px;
    }

    &-2 {
      @include responsive(370px) {
        font-size: 7vw;
      }
    }

    &-3 {
      @include responsive($mobile) {
        font-size: 5.5vw;
      }
    }
  }

  &-sub {
    font-family: Inter-Light;
    font-style: normal;
    font-size: 15px;
    line-height: 134.52%;
    /* or 24px */

    text-align: center;

    color: #ffffff;

    opacity: 0.5;
  }

  &-content {
    font-family: Inter-Light;
    font-style: normal;
    font-size: 18px;
    line-height: 134.52%;
    /* or 24px */

    text-align: center;

    color: #ffffff;

    opacity: 0.6;

    @include responsive($mobileL) {
      font-size: 15px;
    }
  }
}

.obStartButton {
  position: relative;
  width: 357px;
  height: 56px;

  background: #0ef1db;
  border-radius: 10px;

  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 134.52%;
  /* or 24px */

  text-align: center;

  color: #151619;

  @include responsive($mobileL) {
    width: 70%;
    min-height: 35px;
    height: 40px;
    font-size: 16px;
    padding: 0 0 0 0;
  }
}

.obSkip {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-decoration-line: underline;

  color: #ffffff;

  opacity: 0.4;

  position: absolute;
  bottom: 58px;
  left: 49px;

  cursor: pointer;

  &-2 {
    @include responsive($mobile) {
      @include center;
      bottom: 35px;
    }
  }
}

// progress circles

.obProgress {
  padding-top: 50px;
  position: relative;
  left: -20px;
  white-space: nowrap;

  div {
    position: relative;
    display: inline-block;
  }

  @include responsive($mobile) {
    // display: none;
  }
}

.obProgressHint {
  position: absolute !important;
  bottom: 34px;
  margin-left: 5px;
  font-size: 12px;
  opacity: 0.6;
  text-align: left;

  -webkit-animation: fadeInOut ease 6s;
  animation: fadeInOut ease 6s;

  @include responsive($mobile) {
    top: 30px;
    left: 12vw;
    font-size: 10px;
    width: 120%;
    white-space: nowrap;
    overflow: hidden;
  }

  @include responsive(313px) {
    left: 10px;
  }

  &-cutText {
    @include responsive(400px) {
      display: none;
    }
  }
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.6;
  }
}

.obCircle1 {
  width: 16px;
  height: 16px;
  border-radius: 100px;

  bottom: 3.5px;

  background: #15161c;
}

.obLine1 {
  width: 85px;
  height: 0px;

  bottom: 10px;
  left: 10px;

  border: 1px solid #15161c;

  @include responsive($mobile) {
    width: 20%;
  }

  @include responsive(313px) {
    display: none !important;
  }
}

.obCircle2 {
  width: 16px;
  height: 16px;
  border-radius: 100px;

  left: 18px;
  bottom: 3.5px;

  background: #15161c;
}

.obLine2 {
  width: 85px;
  height: 0px;

  bottom: 10px;
  left: 25px;

  border: 1px solid #15161c;

  @include responsive($mobile) {
    width: 20%;
  }

  @include responsive(313px) {
    display: none !important;
  }
}

.obCircle3 {
  width: 16px;
  height: 16px;
  border-radius: 100px;

  left: 33px;
  bottom: 3.5px;

  background: #15161c;
}

.obLine3 {
  width: 85px;
  height: 0px;

  bottom: 10px;
  left: 40px;

  border: 1px solid #15161c;

  @include responsive($mobile) {
    width: 20%;
  }

  @include responsive(313px) {
    display: none !important;
  }
}

.obCircle4 {
  width: 16px;
  height: 16px;
  border-radius: 100px;

  left: 47px;
  bottom: 3.5px;

  background: #15161c;
}

.obCircleActive {
  width: 24px;
  height: 24px;
  border-radius: 100px;
  background: #0ef1db;
  bottom: 0;
}

.obCircleActiveSmall {
  border-radius: 100px;
  background: #0ef1db;
  bottom: 4px;
}

.obLineActive {
  border: 2px solid #0ef1db;
}

// connect

.obConnectBox {
  position: relative;
  width: 180px;
  height: 110px;
  background: rgba(51, 55, 63, 0.5);
  border-radius: 10px;
  margin-bottom: 15px;

  @include responsive($mobile) {
    width: auto;
    max-width: 180px;
    flex: 1 1 180px;
  }
}

.obConnectServiceText {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 134.52%;
  /* identical to box height, or 19px */

  color: #8e8f92;

  position: relative;
  float: left;
  top: 10px;
  left: 12px;
}

.obConnectRows {
  align-items: center;
  position: relative;
  .row {
    margin: 0 !important;
    justify-content: center;

    @include responsive($mobile) {
      display: block;
      justify-content: none;
      margin-left: auto !important;
      margin-right: auto !important;
    }
  }
}

.connectOval {
  position: relative;
  width: 40px;
  height: 40px;
  background-color: #1d2025;
  border-radius: 8px;
  float: right;
  top: 4px;
  right: 4px;
  overflow: hidden;

  img {
    position: relative;
    top: 5.2px;
    // padding: 2px;
    height: 21px;
  }
}

.ob3ConnectOval {
  width: 50px;
  margin-top: 40px;
}

.obConnectLink {
  color: white;
  margin-top: 10px;
  font-size: 0.7em;
  padding: 0 !important;
  width: 90px;
  height: 20px;

  font-family: Inter-Light;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  text-decoration-line: underline;

  color: #0ef1db;

  position: absolute;
  bottom: 12px;
  left: 3px;
  margin-left: 10px;

  cursor: pointer;
}

.obDisconnectLink {
  color: white;
  margin-top: 10px;
  font-size: 0.7em;
  padding: 0 !important;
  width: 90px;
  height: 20px;

  font-family: Inter-Light;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  text-decoration-line: underline;

  color: white;
  opacity: 0.5;

  position: absolute;
  bottom: 12px;
  left: 3px;

  cursor: pointer;
}

.ovalNoBckgr {
  background: none !important;
}

.disabledButton {
  background-color: grey !important;
  margin-top: 10px;
  font-size: 0.7em;
  padding: 0 !important;
  width: 90px;
  height: 20px;
  border-radius: 6px;
  color: white;
  cursor: default !important;

  &:hover {
    transform: none;
    cursor: default;
    color: white;
  }
}

.obConnectUsernameText {
  position: relative;
  float: left;
  left: 12px;
  top: 10px;

  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 134.52%;
  /* or 16px */

  color: #ffffff;

  width: 100px;
  text-align: left;
}

.obNextButton {
  position: absolute;
  float: right;
  right: 48px;
  bottom: 39px;
  padding: 0 !important;

  width: 176px;
  height: 56px;

  background: #282c32;
  border-radius: 10px;

  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 134.52%;
  /* or 24px */

  text-align: center;

  color: #ffffff;

  &-1 {
    @include responsive($mobile) {
      position: relative;
      right: auto;
      float: none;
      margin-top: 30px;

      @include responsive(313px) {
        min-width: none;
        width: 100%;
      }
    }
  }

  &-2 {
    @include responsive($mobile) {
      // position: relative;
      right: auto;
      float: none;
      bottom: 80px;
      @include center;

      &:hover {
        @include center;
      }

      @include responsive(313px) {
        min-width: none;
        width: 75%;
      }
    }
  }
}

.obCircles {
  position: absolute;
  bottom: 25px;
}

.obCircle {
  opacity: 0.9;
  border-radius: 19px;
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.4);
  position: relative;
}

.cActive {
  width: 30px;
  height: 30px;
  background-color: #1d2c47;
  color: white;
  font-size: 0.8em;
  padding: 5px 0 0 2px;
  bottom: 3.5px;
}

.cInactive {
  width: 22px;
  height: 22px;
  color: #1d2c47;
  border: 1px solid #1d2c47;
  padding: 2px 0 0 2px;
  font-size: 0.75em;
}

// spotify connect

.obSpotifyInputGroup {
  position: relative;
  left: 10%;
  top: 14%;
  width: 439px;
  margin-top: 12px;
  box-shadow: none;

  @include center;

  @include responsive($mobileL) {
    width: 85%;
  }

  @include responsive($mobile) {
    width: 100%;
  }
}

.obSpotifyInput {
  height: 56px;

  background: #282c32;
  border-radius: 10px;

  color: white;

  &:focus,
  &:hover,
  &:visited {
    background: #282c32;
    border: none;
    color: white;
  }

  &:enabled {
    background: #282c32;
    border: none;
    color: white;
  }

  cursor: text;
}

.spotifyInputGroupText {
  background: #282c32;
  border: none;
  padding-left: 15px;

  border-radius: 10px;
}

.whiteIconAddon {
  filter: contrast(0) brightness(180%) !important;
}

.connectBigOval {
  width: 50px !important;
  height: 50px !important;
  left: 51px;
  top: 25px;
}

.spotifyProfileName {
  margin-top: 40px;
  color: white;
  font-family: Industry;
  font-size: 0.8em;
}

.obSpotifyConnectDropdown {
  position: absolute;
  width: 439px;
  margin-top: -5px;
  max-height: 200px;
  background-color: #282c32;
  border-radius: 0 0 10px 10px;
  overflow-y: scroll;
  z-index: 999;
  padding-bottom: 10px;

  @include center;

  &::-webkit-scrollbar-track {
    background: #282c32;
    border-radius: 10px;
  }

  @include responsive($mobileL) {
    width: calc(85% - 70px);
  }

  @include responsive($mobile) {
    margin-top: 7px;
    width: calc(100% - 80px);
    padding-top: 7px;
  }
}

.spCnItem {
  width: 100%;
  color: white;
  text-align: left;
  padding-left: 15px;

  &:hover {
    background-color: #525f7f;
  }

  @include responsive($mobileL) {
    height: 29px;
    font-size: 18px;
  }
}

.obSpotifyConnectArtistImageOuterWrapper {
  position: relative;
  height: 130px;
  justify-content: center;
  text-align: center;
  overflow: visible;

  margin-top: 20px;

  text-align: center;

  @include responsive($mobileL) {
    margin-top: 50px;
  }

  @include responsive($mobile) {
    margin-top: 35px;
    display: inline-flex;
  }
}

.obSpotifyConnectArtistImageWrapper {
  width: 100px;
  height: 100px;
  position: absolute;

  border-radius: 100px;

  @include center;

  overflow: hidden;
}

.obSpotifyProfileName {
  position: absolute;
  bottom: 0;

  width: 100%;

  text-align: center;

  overflow: visible;
  white-space: nowrap;

  margin-top: 0;

  font-family: Inter;
  font-size: 16px;
  line-height: 134.52%;
  /* identical to box height, or 22px */

  color: #ffffff;

  @include responsive($mobile) {
    width: 100vw;
    left: -50vw;
  }
}

// OB 3

.ob3ProfileBoxes {
  display: inline-flex;
  margin-top: 50px;
  margin-left: 50px;

  .ob3ProfileBox {
    margin-right: 20px;
  }

  @include responsive($mobileL) {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    row-gap: 10px;
    column-gap: 10px;

    padding-bottom: 100px;
  }

  @include responsive($mobile) {
    padding-bottom: 170px;
  }
}

.ob3ProfileBox {
  width: 150px;
  height: 152px;
  position: relative;
  display: flex;
  justify-content: center;

  border-radius: 10px;

  @include responsive($mobileL) {
    margin-left: 0;
  }

  background-color: #282c32;

  cursor: pointer;

  &-checkCircle {
    position: absolute;
    right: 10px;
    top: 10px;
  }
}

.ob3SpotifyProfileName {
  position: absolute;
  top: 100px;
  padding: 0 1px 0 1px;

  overflow: visible;

  font-family: Inter;
  font-style: normal;
  font-size: 14px;
  line-height: 134.52%;
  /* identical to box height, or 22px */

  display: flex;
  align-items: center;
  text-align: center;

  color: #ffffff;
}

.ob3fbStatusFeedback {
}

$primaryColor: #0ef1db;

.pbCenter {
  @include center;

  &-change {
    color: $primaryColor;
    margin-top: 5px;
    font-size: 13px;
    text-decoration: underline;
    cursor: pointer;
  }
}

.alice-carousel__stage-item {
  width: 170px !important;

  @include responsive($mobileL) {
    // left: -80px;
  }
}

.alice-carousel__wrapper {
  padding-left: 0 !important;
  padding-right: 0;

  @include responsive($mobileL) {
    position: relative;
    width: 114.5% !important;
    left: -7.5%;
  }

  @include responsive($mobile) {
    position: relative;
    width: 124% !important;
    left: -12%;
  }
}

.alice-carousel__prev-btn-item {
  position: absolute;
  bottom: 95px;
  left: 15px;
  width: 2em;
  filter: contrast(0.2) opacity(0.8);

  @include responsive($mobileL) {
    left: 0;
  }
}

.alice-carousel__next-btn-item {
  position: absolute;
  right: 15px;
  bottom: 95px;
  width: 2em;
  filter: contrast(0.2) opacity(0.8);

  @include responsive($mobileL) {
    right: 0;
  }
}

.alice-carousel__next-btn-item > span,
.alice-carousel__prev-btn-item > span {
  display: none !important;
}

.alice-carousel__next-btn-item {
  &::before {
    content: url("../../assets/icons/angle-right.svg");
    font-size: 3em;
    color: grey;
  }
}

.alice-carousel__prev-btn-item {
  &::before {
    content: url("../../assets/icons/angle-left.svg");
    font-size: 3em;
    color: grey;
  }
}

.alice-carousel__dots-item {
  background-color: #d8d8d8;
  &.__active,
  &:hover {
    background-color: rgba(29, 44, 71, 0.6) !important;
  }
}

// OB 4

.ob4 {
  &-buttonsWrapper {
    position: relative;
    width: 285px;
    height: 192px;

    // padding-top: 8%;

    @include center;
  }

  &-button {
    width: 285px;
    height: 56px;
    background-color: rgba(51, 55, 63, 0.5);
    margin-bottom: 12px;
    border-radius: 10px;

    cursor: pointer;

    &:hover {
      background-color: $lightGrey;
    }

    &-text {
      font-family: Inter;
      font-style: normal;
      font-size: 16px;
      line-height: 134.52%;

      color: #ffffff;

      position: relative;
      left: 16px;
      top: 18px;
      float: left;
    }

    &-logoBox {
      background: #1d2025;
      border-radius: 8px;

      width: 36px;
      height: 36px;

      position: relative;
      float: right;
      right: 10px;
      top: 10px;

      padding-top: 4px;
    }

    &-comment {
      font-size: 12px;
      opacity: 0.4;
    }
  }
}

.oboarding-container {
}
