$primaryColor: #0ef1db;
$darkColor: #1d2025;
$lightGrey: rgba(255, 255, 255, 0.44);

$mobileS: 320px;
$mobile: 480px;
$mobileL: 768px;
$dSmall: 1024px;

.cursor-pointer {
  cursor: pointer !important;
}

@mixin responsive($query) {
  @media (max-width: + $query) {
    @content;
  }
}

@mixin center() {
  left: 50%;
  transform: translate(-50%, 0);
}

.newReleaseTitle {
  position: absolute;
  width: 780px;
  height: 56px;
  top: 169px;

  @include center;

  font-family: Inter-Bold;
  font-style: normal;
  font-weight: bold;
  font-size: 42px;
  line-height: 134.52%;
  /* or 56px */

  text-align: center;

  color: #ffffff;
}

// release progress circles

.nrpWrapper {
  position: absolute;
  width: 100%;
  height: 100px;
  top: 295px;

  @include responsive($mobileL) {
    // width: 70vw;
    width: 100%;
  }

  @include responsive($mobile) {
    margin-left: 5vw;
  }
}

.newReleaseProgress {
  position: absolute;

  width: 374px;

  @include center;

  div {
    position: relative;
    display: inline-block;
  }

  @include responsive($mobileL) {
    width: 50vw;
    @include center;
  }

  @include responsive($mobile) {
    width: 75vw;
    margin-left: -1vw;
  }

  @include responsive($mobileS) {
    width: 75vw;
    margin-left: -7vw;
  }
}

// release progress text

.nrpTextDiv {
  position: absolute;

  width: 425px;
  top: 35px;

  text-align: center;

  display: flex;
  justify-content: space-between;

  @include center;

  @include responsive($mobileL) {
    width: 60vw;
    // margin-left: -2vw;
    // transform: none;
  }

  @include responsive($mobile) {
    width: 85vw;
    left: 3vw;
    transform: none;
    margin: 0.25rem;
  }

  @include responsive($mobileS) {
    width: 95vw;
    left: -4vw;
    transform: none;
    margin: 0.25rem;
  }

  div {
    display: table-cell;
    text-align: center;
    width: 72px;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 134.52%;
    /* or 22px */

    // text-align: center;

    color: #8e8f92;
    padding-left: 0.5rem;
  }

  color: white;
}

$rLineWidth: 20%;

.nrpCircle1 {
  width: 16px;
  height: 16px;
  border-radius: 100px;

  bottom: 3.5px;

  background: #1d2025;
}

.nrpLine1 {
  width: 85px;
  height: 0px;

  bottom: 10px;
  left: 10px;

  border: 1px solid #1d2025;

  @include responsive($mobileL) {
    width: $rLineWidth;
  }

  @include responsive($mobile) {
    width: 14vw;
  }
}

.nrpCircle2 {
  width: 16px;
  height: 16px;
  border-radius: 100px;

  left: 18px;
  bottom: 3.5px;

  background: #1d2025;
}

.nrpLine2 {
  width: 85px;
  height: 0px;

  bottom: 10px;
  left: 25px;

  border: 1px solid #1d2025;

  @include responsive($mobileL) {
    width: $rLineWidth;
  }

  @include responsive($mobile) {
    width: 14vw;
  }
}

.nrpCircle3 {
  width: 16px;
  height: 16px;
  border-radius: 100px;

  left: 33px;
  bottom: 3.5px;

  background: #1d2025;
}

.nrpLine3 {
  width: 85px;
  height: 0px;

  bottom: 10px;
  left: 40px;

  border: 1px solid #1d2025;

  @include responsive($mobileL) {
    width: $rLineWidth;
  }

  @include responsive($mobile) {
    width: 14vw;
  }
}

.nrpCircle4 {
  width: 16px;
  height: 16px;
  border-radius: 100px;

  left: 47px;
  bottom: 3.5px;

  background: #1d2025;
}

.nrpCircleActive {
  width: 24px;
  height: 24px;
  border-radius: 100px;
  background: #0ef1db;
  bottom: 0;
}

.nrpLineActive {
  border: 2px solid #0ef1db;
}

.nrpTextActive {
  color: #ffffff !important;
  opacity: 0.8;
}

.nrpTextActiveFix1 {
  margin-left: 3px !important;
  color: white !important;
  opacity: 0.8 !important;
}

.nrpTextActiveFix2 {
  margin-left: 12px !important;
  color: white !important;
  opacity: 0.8 !important;
}

// basic information form

.nrForm {
  position: absolute;
  top: 454px;

  @include center;

  line-height: 60px;

  @include responsive($dSmall) {
    width: 90%;
  }
}

.nrBasicInformationHeader {
  position: absolute;
  width: 400px;
  height: 43px;

  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 134.52%;
  /* identical to box height, or 43px */

  color: #ffffff;
}

.nrInputText {
  background-color: #1d2025;
  border: none;
  border-radius: 10px;
}

.nrInput {
  background-color: #1d2025;
  border: none;
  height: 100%;
  border-radius: 10px;

  height: 56px;

  font-size: 16px;
  font-family: Inter-Light;

  &-opacityFix {
    opacity: 1 !important;
  }

  &:hover,
  &:active,
  &:focus {
    background-color: #1d2025 !important;
    // color: #8e8f92;
    color: white;
  }

  &:focus,
  &:active {
    border: 1px solid #0ef1db;
    background-color: #1d2025 !important;
    box-sizing: border-box;
  }

  &-thin {
    height: 45px;
  }

  &-populated {
    font-family: "Inter-Medium";
    color: white;
    background-color: #1d2025;
    border: none;
    border-radius: 10px;

    &:focus {
      background-color: #1d2025 !important;
      color: white;
      border: 1px solid #0ef1db;
    }
  }
}

.nrInputGroup {
  box-shadow: none;

  // @include responsive($dSmall) {
  //   width: 100%;
  // }

  &-short {
    width: 336px;
    @include responsive($dSmall) {
      width: 34.5vw;
    }

    @include responsive($mobileL) {
      width: calc(80% + 5vw);
    }

    @include responsive($mobile) {
      width: 85%;
    }
  }

  &-title {
    @include responsive($dSmall) {
      width: 41.5vw;
    }

    @include responsive($mobileL) {
      width: auto;
    }

    @include responsive($mobile) {
      width: 80vw;
    }
  }
}

.iuc3 {
  margin-left: 9px;
}

.iuc3,
.iuc3b,
.iuc3d {
  z-index: 90 !important;
}

.InputContainer{
  display: flex;
  justify-content: space-between;
  @include responsive($mobileL) {
    flex-direction: column;
    
  }
}



.nrAlbumTitleInputWrapper {
  position: relative;
  width: 100%;
  margin-right: 16px;
  
  @include responsive($mobileL) {
   margin-bottom: 24px;
    
  }
  
}

.VersionContainer{
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 100%;
 
 
}

.nrAlbumVersionInputWrapper {
  position: relative;
  width: 100%;  
}

.nrCoverArtTitle {
  position: relative;
  width: 79px;
  height: 22px;

  margin-bottom: 20px;

  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 134.52%;
  /* identical to box height, or 22px */

  color: #ffffff;

  @include responsive($mobileL) {
    @include center;
  }
}

.nrCoverArtUploadWrapper {
  height: 300px;

  @include responsive($mobileL) {
    height: 600px;
  }
}

.nrCoverArtUploadField {
  position: absolute;
  display: inline-block;
  width: 300px;
  height: 300px;

  background: #1d2025;
  border-radius: 10px;

  cursor: pointer;

  @include responsive($mobileL) {
    @include center;
  }
}

.nrCoverArtInput {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.nrCoverArtPlaceholder {
  padding: 125px;
}

.nrCoverArtPreview {
  position: static;
  width: 100%;
  height: 100%;
  vertical-align: inherit;
}

.uploadImageCircle {
  position: relative;
  width: 28px;
  height: 28px;
  bottom: 78px;
  left: 26px;
  box-sizing: border-box;

  color: #1d2025;
}

.coverArtUploadRightText {
  display: inline-block;
  position: relative;
  width: 330px;
  height: 300px;

  top: 27px;
  left: 340px;

  font-family: Inter-Light;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 22px */

  color: #ffffff;

  @include responsive($mobileL) {
    @include center;
    top: 360px;
  }

  @include responsive($mobile) {
    margin-left: 0;
  }

  @include responsive(420px) {
    width: 300px;
  }
}

.coverArtUploadRightTextList {
  margin-left: -20px;
  line-height: 26px;
}

.coverArtErr {
  font-family: Inter-Medium;
  color: red;
}

.coverArtOk {
  font-family: Inter;
  color: $primaryColor;
}

.nrTick {
  width: 15px;
  height: 15px;
}

.nrImgValMsg {
  height: 50px;

  @include responsive($mobileL) {
    @include center;
    overflow: visible;
    white-space: nowrap;
  }
}

.nrExpFieldsHint {
  position: relative;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 134.52%;
  color: #ffffff;
  opacity: 0.6;

  &-2 {
    float: right;
    bottom: 25px;
    right: 110px;

    @include responsive($dSmall) {
      float: left;
      left: 45vw;
    }
  }

  @include responsive($mobileL) {
    opacity: 0;
  }
}

.nrExpandableFieldsWrapper {
  @include responsive($mobileL) {
    margin-top: -40px;
  }
}

.nrPrimaryArtistInputOuterWrapper {
  position: absolute;
  // width: 336px;
  width: 400px;

  @include responsive($mobileL) {
    width: 100%;
    position: relative;
  }
}

.nrPrimaryArtistInputWrapper {
  position: relative;
  width: 100%;

  @include responsive($dSmall) {
    width: 42vw;
  }

  @include responsive($mobileL) {
    width: 100%;

    div > div.ruslanSelect__control {
      width: 100%;
    }

    .iuc2_0 {
      line-height: inherit;
    }
  }
}

.nrPrimaryArtistInputWrapperExtended {
  width: 100%;
  margin-bottom: 32px;

  @include responsive($dSmall) {
    width: 42vw;
  }

  @include responsive($mobileL) {
    width: 100%;
  }
}

.interMediumInput {
  font-family: Inter-Medium !important;
  color: white;
  opacity: 1;
}

.interMediumPaddingTop {
  padding-top: 20px !important;
}

.nrPlus {
  position: absolute;
  width: 56px;
  height: 56px;
  left: 344px;
  bottom: 0px;

  z-index: 0;

  background: #1d2025;
  border-radius: 10px;

  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  /* identical to box height, or 27px */

  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;

  color: #0ef1db;

  cursor: pointer !important;

  @include responsive($dSmall) {
    // left: 36.2vw;
    left: auto;
    float: right;
    right: 0;
  }

  @include responsive($mobileL) {
    float: right;
    left: auto;
    right: 0;
  }

  @include responsive(612px) {
    // left: 79.5vw;
  }

  @include responsive($mobile) {
  }
}

.nrContributorsOuterWrapper {
  position: absolute;
  width: 400px;
  float: right;
  right: 0;

  @include responsive($dSmall) {
    width: 50%;
  }

  @include responsive($mobileL) {
    position: relative;
    left: 0;
    top: 30px;
    width: 100%;
  }
}

.nrContributorsWrapper {
  position: relative;
  max-width: 400px;
  height: 56px;

  @include responsive($dSmall) {
    width: 100%;
    max-width: none;
  }
}

.nrContributorsWrapperExtended {
  width: 400px;
  margin-top: 32.5px;

  @include responsive($dSmall) {
    width: auto;
  }
}

.nrContributorsSelectGroup {
  width: 139px;

  @include responsive($dSmall) {
    width: 17vw;
  }

  @include responsive($mobileL) {
    width: 30vw;
  }

  @include responsive(344px) {
    margin-top: 20px;
    width: 37vw;
  }

  div.ruslanSelect__control > div.ruslanSelect__value-container {
    @include responsive(407px) {
      font-size: 3.5vw;
    }
    @include responsive(451px) {
      // @include responsive(339px) {
      font-size: 12px;
      line-height: 13px;
      top: 10px;
      height: 30px;
    }
  }

  div.ruslanSelect__control > div.ruslanSelect__indicators > div > img {
    @include responsive(449px) {
      position: relative;
      left: 5px;
      top: 5px;
    }
    @include responsive(407px) {
      position: relative;
      left: 5px;
    }
    @include responsive(339px) {
      position: relative;
      margin-top: 15px;
      left: 5px;
    }
  }
}

.nrSelectGroup {
  width: 400px;
}

// .dropdownArrow {

// }

.ruslanSelect__value-container {
  // padding-bottom: 20px; // <== input field position // TODO: remove?
}

.ruslanSelect__placeholder {
  // padding-bottom: 11px;
}

.ruslanSelect__control {
  background-color: #1d2025 !important;
  border: none !important;
  height: 100%;
  border-radius: 10px !important;
  width: 100%;
  line-height: 24px;

  // line-height: 54px; // <== setting for contributors

  height: 56px;

  color: #8e8f92;

  box-shadow: none !important;

  &:hover,
  &:active,
  &:focus {
    background-color: #1d2025;
    color: #8e8f92;
    color: white;
  }

  &:focus,
  &:active {
    border: 1px solid #0ef1db !important;
    box-sizing: border-box;
  }
  color: white;

  font-family: Inter-Medium;
  font-style: normal;
  font-size: 16px;

  &:disabled {
    background-color: #1d2025;
  }
}

.ruslanSelect__single-value {
  padding-bottom: 15px !important;
}

.ruslanSelect__control--menu-is-open {
  border: 1px solid #0ef1db !important;
}

.ruslanSelect__menu {
  height: 194px;

  z-index: 9999 !important;

  background: #1d2025 !important;
  box-shadow: 0px 2px 14px rgb(0, 0, 0);
  border-radius: 10px !important;

  margin-top: 8px;

  // custom scrollbar

  padding: 14px 14px 14px 0;

  @include responsive(420px) {
    width: 90vw !important;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 2px !important;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.2) !important;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.44) !important;
    margin: 2px !important;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555 !important;
    width: 20px !important;
  }
}

.ruslanSelect__input {
  > div {
    width: 100% !important;
    font-family: Inter-Medium !important;
  }
}

.ruslanSelect__option {
  text-align: left;
  line-height: 20px;
  &:active {
    background-color: rgba(255, 255, 255, 0.1) !important;
  }
}

.nrContributorsInputGroup {
  position: absolute;

  width: 189px;
  height: 56px;
  left: 147px;
  bottom: 0px;

  background: #1d2025;
  border-radius: 10px;

  @include responsive($dSmall) {
    left: 17.5vw;
    width: 43%;
  }

  @include responsive($mobileL) {
    left: 31vw;
    width: calc(45% + 5vw);
  }

  @include responsive($mobile) {
    width: 45vw;
  }

  @include responsive(344px) {
    left: 38vw;
    width: 30vw;
  }
}

.nrcigExtended {
  width: 250px;

  @include responsive($dSmall) {
    width: 27vw;
  }

  @include responsive($mobileL) {
    width: 57.5vw;
  }

  @include responsive($mobile) {
    width: 57.5vw;
  }

  @include responsive($mobile) {
    width: 57.5vw;
  }

  @include responsive(344px) {
    width: 49vw;
  }
}

.nrpigExtended {
  width: 100% !important;
}

.nrContributorsPlaceholder {
  bottom: 0px;
}

.playtreksSelectActive {
  border: 1px solid $primaryColor;
  border-radius: 10px;
}

.nrHr {
  position: relative;
  width: 864px;
  height: 0px;

  top: 30px;

  z-index: -999;

  opacity: 0.4;
  border: 1px solid #151618;

  @include responsive($dSmall) {
    width: 100%;
  }

  @include responsive($mobileL) {
    top: 42px;
  }
}

.nrFormBelowHr {
  position: relative;
  top: 1100px;
  margin-top: 30px;
}

.nrPrimaryGenreWrapper {
  position: relative;
  width: 400px;
  height: 56px;

  background: #1d2025;
  border-radius: 10px;

  @include responsive($dSmall) {
    width: 47.5%;
  }

  @include responsive($mobileL) {
    width: 100%;
  }
}

.nrSecondaryGenreWrapper {
  position: relative;
  width: 400px;
  height: 56px;
  float: right;
  top: -116px;

  @include responsive($dSmall) {
    width: 50%;
  }

  @include responsive($mobileL) {
    float: none;
    top: -30px;
    width: 100%;
  }
}

.nrLanguageWrapper {
  position: relative;
  width: 400px;
  height: 56px;
  float: left;
  top: -18px;

  @include responsive($dSmall) {
    width: 47.5%;
  }

  @include responsive($mobileL) {
    top: 0;
    width: 100%;
  }
}

.nrExplicitLyricsWrapper {
  position: relative;
  width: 400px;
  height: 56px;
  float: right;
  top: -13px;

  @include responsive($dSmall) {
    width: 50%;
  }

  @include responsive($mobileL) {
    float: none;
    top: 100px;
    width: 100%;
  }
}

.nrExplicitLyricsText {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 134.52%;

  position: relative;
  left: 10px !important;

  color: #ffffff;
  position: absolute;
}

.nrExplicitLyricsSwitch {
  position: absolute;
  float: right;
  top: -14px;

  @include responsive($mobileL) {
    top: -70px;
  }
}

.nrAlbumFormatWrapper {
  position: relative;
  width: 400px;
  height: 56px;
  float: right;
  top: -77px;
}

.nrReleaseUpcCodeWrapper {
  position: relative;
  width: 400px;
  height: 56px;
  top: 9px;

  @include responsive($dSmall) {
    width: 47.5%;
  }

  @include responsive($mobileL) {
    top: 40px;
    width: 100%;
  }
}

.nrReleaseUpcCodeCheckbox {
  position: relative;
  // width: 18px;
  // height: 18px;
  top: 10px;
}

.nrReleaseUpcCodeCheckboxText {
  position: relative;
  width: auto;
  height: 16px;
  left: 25px;
  top: -5px;

  @include responsive($dSmall) {
    font-size: 1.2vw;
  }

  @include responsive($mobileL) {
    font-size: 12px;
  }

  @include responsive($mobile) {
    font-size: 2.2vw;
  }
}

.nrCatalogNumberWrapper {
  position: relative;
  width: 400px;
  height: 56px;
  float: right;
  top: -47.5px;

  @include responsive($dSmall) {
    width: 50%;
  }

  @include responsive($mobileL) {
    top: 100px;
    float: none;
    width: 100%;
  }
}

.nrCatalogNumberCheckbox {
  position: relative;
  width: 18px;
  height: 18px;
  float: left;
  top: 10px;
}

.nrCatalogNumberCheckboxText {
  position: relative;
  width: 410px;
  height: 16px;
  float: left;
  top: -7px;
  left: 25px;

  @include responsive($dSmall) {
    font-size: 1.2vw;
    left: 5px;
    top: 10px;
  }

  @include responsive($mobileL) {
    width: auto;
    font-size: 12px;
    margin-top: 20px;
    margin-left: -20px;
  }

  @include responsive(961px) {
    left: 25px;
    top: -5px;
  }

  @include responsive($mobile) {
    font-size: 2.2vw;
  }
}

.nrLabelWrapper {
  position: relative;
  width: 400px;
  height: 56px;

  @include responsive($dSmall) {
    width: 47.5%;
  }

  @include responsive($mobileL) {
    top: 88px;
    width: 100%;
  }
}

.nrLicenseInformationHeader {
  position: relative;
  width: 400px;
  height: 43px;

  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 134.52%;
  /* identical to box height, or 43px */

  color: #ffffff;
}

.nrReleaseDateWrapper {
  position: relative;
  width: 400px;
  height: 56px;
  top: -40px;

  @include responsive($dSmall) {
    width: 47.5%;
  }

  @include responsive($mobileL) {
    width: 100%;
  }
}

.nrRightAddon {
  border-radius: 0 10px 10px 0 !important;
  border: none;
  background-color: #1d2025;
  z-index: 4;

  &-opacityFix {
    opacity: 1 !important;
  }
}

.nrZzero {
  z-index: 0;
}

.nrAddonRightIcon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.nrPreviouslyReleasedText {
  position: relative;
  width: 282px;
  height: 22px;
  float: right;
  top: -78px;
  right: 110px;

  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 134.52%;
  /* identical to box height, or 22px */

  color: #ffffff;

  @include responsive($dSmall) {
    width: 50%;
    right: 0;

    font-size: 16px;
  }

  @include responsive($mobileL) {
    float: none;
    top: 7px;
    width: auto;
  }

  @include responsive($mobile) {
    float: none;
    top: -5px;
    left: 6px;
    width: 200px;
  }

  @include responsive(344px) {
    float: none;
    top: -14px;
    left: 6px;
    width: 140px;
  }
}

.nrPreviouslyReleasedSwitch {
  float: right;
  top: -91px;
  left: 280px;

  @include responsive($dSmall) {
    left: 50%;
  }

  @include responsive($mobileL) {
    float: right;
    left: auto;
    top: -30px;
    right: 0;
  }
}

.nrSwitch {
  position: relative;
  width: 85px;
  height: 46px;

  cursor: pointer !important;

  background: #1d2025;
  border-radius: 34px;

  .nrHandle {
    position: relative;
    width: 34px;
    height: 34px;
    background: white;
    border-radius: 100px;
    margin: 6px;
  }

  .nrHandleOff {
    float: left;
  }

  .nrHandleOn {
    float: right;
    background: $primaryColor;
  }

  .nrOn,
  .nrOff {
    position: absolute;

    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */

    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 134.52%;
    /* identical to box height, or 22px */

    color: #ffffff;
  }

  .nrOff {
    margin-top: 12px;
    margin-left: 47px;
  }

  .nrOn {
    margin-top: 12px;
    margin-left: 10px;
  }
}

.nrLicenseTypeWrapper {
  position: relative;
  width: 310px;
  height: 56px;
  top: -60px;

  @include responsive($dSmall) {
    width: 272px;
  }

  @include responsive($mobileL) {
    top: 75px;
    width: 100%;
  }
}

.nrBottomPushDownMobile {
  @include responsive($mobileL) {
    margin-top: 70px !important;
  }
}

.nrLicenseInfoWrapper {
  position: relative;
  width: 400px;
  height: 56px;
  top: -84px;

  @include responsive($dSmall) {
    width: 47.5%;
  }

  @include responsive($mobileL) {
    top: 50px;
    width: 100%;
  }
}

.nrLicenseHolderWrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;

  position: relative;
  width: 400px;
  height: 56px;
  float: right;
  top: -116px;

  z-index: 1;

  @include responsive($mobileL) {
    float: none;
    top: -70px;
    width: 100%;
  }
}

.iuc6a {
  z-index: 1;
}

.iuc9,
.iuc10 {
  z-index: 100;
}

.iuc8,
.iuc10 {
  margin-left: 88px;
  margin-top: 3.1px;
}

.nrltLeft {
  width: 85px;
  height: 56px;
  margin-right: 8px;
}

.nrCopyrightWrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  position: relative;
  height: 56px;
  width: 560px;

  &-pushDown {
    @include responsive($mobileL) {
      top: 25px !important;
    }
  }

  @include responsive($dSmall) {
    width: 66.5%;
    z-index: 1;
  }

  @include responsive($mobileL) {
    top: -10px;

    width: 100%;
  }
}

.CopyrightWrapper {
  @include responsive($mobileL) {
    margin-top: 7rem;
  }
}

.support-article {
  margin-top: 1rem;
  @include responsive($mobileL) {
    margin-top: 3rem;
  }
}

.nrcLeft {
  width: 85px;
  height: 56px;
  margin-right: 8px;
}

.iuc9 {
  margin-left: 12px;
  margin-top: 3.1px;
}

.nrPreviouslyReleasedDateWrapper {
  position: relative;
  width: 400px;
  height: 56px;

  float: right;
  left: 368px;

  z-index: 5;

  @include responsive($dSmall) {
    position: absolute;
    float: left;
    left: 50%;
    width: 50%;
  }

  @include responsive($mobileL) {
    float: none;
    margin-top: 43.5px;
    left: 0;
    width: 100%;
  }
}

.nrSaveAndUploadTracksButton {
  position: relative;
  width: 400px;
  height: 56px;
  // top: -20px;

  background: #0ef1db;
  border-radius: 10px;

  color: #151619;

  text-align: center;
  font-family: Inter-SemiBold;

  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  @include responsive($mobile) {
    width: 87.5vw;
  }
}

.nrTermsAndConditionsText {
  position: relative;
  width: 306px;
  height: 19px;
  top: 10px;

  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 134.52%;
  /* identical to box height, or 19px */

  color: #ffffff;

  opacity: 0.5;

  @include responsive($mobile) {
    width: 87.5vw;
  }
}

// datepicker

.react-datepicker {
  display: none;
  z-index: 99999;

  @include responsive($mobile) {
    width: 87.5vw !important;
  }
}

.react-datepicker-wrapper {
  display: none !important;
}

.react-datepicker-popper {
  transform: none !important;
  z-index: 999 !important;
}

.react-datepicker__triangle {
  // left: 230px !important;
  display: none !important;
}

.nrReleaseDateCalendar {
  // left: 158px;
  top: 55px;
  width: 400px;
  border-radius: 10px !important;
  border: none !important;
  z-index: 9999 !important;
  line-height: 60px;

  &-popper2 {
    left: auto !important;
    right: 0;
  }
}

.react-datepicker__header {
  background-color: $darkColor !important;
  border: none !important;
  border-radius: 10px !important;
  height: 90px;
  padding-top: 0 !important;

  &__dropdown {
    height: 17px;
    color: white;

    div {
      padding-bottom: 5px;
      span {
        position: relative;
        bottom: 10px;
      }
    }
  }
}

.react-datepicker__month-read-view--down-arrow,
.react-datepicker__year-read-view--down-arrow {
  top: 15px !important;
  left: 5px;
}

.react-datepicker__month {
  background-color: $darkColor !important;
  color: white !important;
  line-height: 35px !important;
  margin-top: 0 !important;

  &-dropdown {
    background-color: $darkColor !important;
    padding-bottom: 0px !important;
  }

  &-option:hover {
    background-color: $primaryColor !important;
  }

  &-option--selected {
    display: none;
  }

  &-read-view--down-arrow {
    width: 0.01px !important;
  }
}

.react-datepicker__year {
  &-dropdown {
    background-color: $darkColor !important;
    padding-bottom: 0px !important;
  }

  &-option:hover {
    background-color: $primaryColor !important;
  }

  &-option--selected {
    display: none;
  }

  &-read-view--down-arrow {
    width: 0.01px !important;
  }
}

.react-datepicker__current-month {
  margin-left: auto;
  margin-right: auto;
  width: 200px;
  height: 30px;
  line-height: 45px !important;
}

.react-datepicker__day,
.react-datepicker__day-name,
.react-datepicker__current-month {
  color: white !important;
}

.react-datepicker__day {
  width: 13% !important;
  &:hover {
    background-color: $lightGrey !important;
  }
}

.react-datepicker__day-name {
  width: 12.5% !important;
}

.react-datepicker__month-container {
  border-radius: 10px !important;
  border: none !important;
  // left: 78px !important;
  background-color: $darkColor !important;
  position: relative;
  width: 100%;
}

.react-datepicker__day--today:not(.react-datepicker__day--keyboard-selected) {
  background-color: rgba(255, 255, 255, 0.44) !important;
  border-radius: 5px !important;
}

.react-datepicker__day--disabled {
  background-color: rgba(255, 255, 255, 0.11) !important;
  border-radius: 5px !important;
}

.react-datepicker__day--keyboard-selected {
  background-color: $primaryColor !important;
}

.react-datepicker__navigation--previous {
  top: 15px !important;
  left: 19px !important;
}

.react-datepicker__navigation--next {
  top: 15px !important;
  right: 19px !important;
}

.react-datepicker__day--selected {
  background-color: $primaryColor !important;
}

.nrCalendarInput {
  padding-top: 24px;
}

.nrCalendarInputPopulated {
  color: white;
  font-family: Inter-Medium;
  padding-top: 24px;
}

.nrCalendarInputBlocker {
  position: absolute;
  height: 56px;
  width: 100%;
  z-index: 1;
  border-radius: 10px;
  opacity: 0;
  z-index: 99999;
}

////

.nrRowDummy {
  z-index: -999;
}

.nrLicenseHolderPushDown {
  top: -21px;

  @include responsive($mobileL) {
    top: -60px;
  }
}

.nrLicenseHolderPushUp {
  top: -172px;

  @include responsive($mobileL) {
    top: -126px !important;
  }

  &-mobile2 {
    @include responsive($mobileL) {
      top: -116px !important;
    }
  }
}

.nrLicenseHolderPushMiddle {
  margin-top: 39px;

  @include responsive($mobileL) {
    margin-top: 25px !important;
  }
}

/// VALIDATION

.nrValTextBox {
  margin-left: 5px !important;
  // margin-top: -4px !important;
  bottom: 17px;
  position: absolute;

  &-absoluteImportant {
    position: absolute !important;
  }

  &-lang {
    bottom: -46px;
  }

  &-title {
    position: absolute !important;
    top: 155px;
    bottom: auto;
  }

  &-version {
    position: absolute !important;
    top: 155px;
  bottom: auto;
  }

  &-licenseType {
    bottom: -45px;
  }

  &-infoImage {
    width: 1.5em;
    height: 1.4em;
  }
}

.nrValTextBoxLower {
  position: absolute;
  margin-left: 5px !important;
  margin-top: 12px !important;

  @include responsive($mobileL) {
    position: absolute !important;
    margin-top: 10px !important;
    margin-left: 2px !important;
  }

  &-2 {
    top: -20px !important;
    z-index: -100 !important;

    @include responsive($dSmall) {
      top: 0 !important;
    }

    @include responsive(961px) {
      top: -22px !important;
    }

    @include responsive($mobileL) {
      position: absolute !important;
      margin-top: 90px !important;
      margin-left: 2px !important;
    }
  }
}

.nrValTextBoxHigher {
  position: absolute !important;
  margin-left: 5px !important;
  bottom: -48px !important;
}

.nrPrimaryValBox {
  position: absolute !important;
  bottom: -46px;
}

.new-release-stepper {
  position: absolute;
  top: 295px;
  width: 864px;
  // padding: 0 0.5rem;
  // left: 22.5%;
  @include center;

  @include responsive($dSmall) {
    width: 90%;
  }

  @include responsive($mobile) {
    width: 100%;
  }
}

.nrBottomFeedback {
  color: red;
  font-size: 13px;
  margin-bottom: 10px;
  line-height: 2rem;

  &-warning {
    color: yellow;
    font-size: 13px;
    margin-bottom: 10px;
    line-height: 2rem;
  }
}
