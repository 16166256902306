@import "../../utils.scss";

.badge-warning {
  color: #212529;
  background-color: #ffc107;
}

.adminHeader {
  color: white;
  font-size: 30px;
  width: 100%;
  text-align: center;
  font-family: Industry;
}

.adminMenu {
  color: white;
  font-size: 20px;
  width: 100%;
  text-align: center;
  font-family: Industry;

  &-active {
    text-decoration: underline;
  }
}

.adminReleases {
  &-csvSwitch {
    float: right;

    &-on {
      position: absolute;
      right: 10px;
      top: 12px;
    }

    &-off {
      position: relative;
    }
  }
}

.adminMessages {
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;

  margin-top: 30px;

  &-userMessageInput {
    width: 30vw;
  }

  &-controls {
    &-wrapper {
      display: block;
      color: white;
    }

    &-input {
      margin-left: 20px;
    }
  }
}

.adminTable {
  width: 80%;

  td,
  th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
    color: white;
  }
}

.adminRoyalties {
  display: block;
  text-align: center;
  padding-bottom: 7rem;

  &-display {
    background-color: $color-dark-one;
    border-radius: 10px;
    color: white;
    margin: 5rem;
    padding: 4.5rem 5rem 0.1rem 5rem;

    &-userSearch {
      text-align: left;

      small {
        position: relative;
        bottom: 0.5rem;
      }
    }

    .my-earnings-section-1 {
      text-align: left;

      &-filters,
      &-aggregationPicker {
        margin-top: 0.3rem;
      }
    }
  }

  &-import {
    &-dropzone {
      &-wrapper {
        width: 100%;
        @include xyFlexCenter;
      }

      width: 300px;
      height: 300px;
      background: #1d2025;
      border-radius: 10px;
      cursor: default;

      &-input {
        height: 100%;
        @include xyFlexCenter;
        color: white;
        font-size: 1.5rem;
        padding: 2rem;
      }
    }

    &-importResult {
      &-msg {
        font-size: 2.5rem;
      }
      &-error {
        color: red;
      }
      &-success {
        color: lawngreen;
      }
    }
  }
}

.adminTransactions {
  h1 {
    color: white;
    text-align: center;
  }
  table {
    width: 80%;
    margin: auto;
    color: white;
    border: 1px solid white;
    td,
    th,
    tr {
      border: 1px solid white;
    }
  }
}

.admin-release-details-link {
  text-decoration: underline;
  color: #98a5e7;
  cursor: pointer;

  &:hover {
    color: #ccc;
    text-decoration: underline;
  }
}

.admin-release-drawer {
  max-width: 40rem;

  @include responsive($mobileL) {
    width: 100%;
  }
}

.groupBtnActive {
  background-color: $primary;
  color: black;
}

.dropdown-width {
  width: 300px;

  @include responsive($mobileL) {
    width: 100%;
  }


}

.userWithdrawalHeader {
  font-family: Inter;
 font-style: normal;
 font-weight: 600;
 font-size: 32px;
 margin-left: 24px;
 color: #fff;
 text-align: center;
}
