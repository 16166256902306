.validate-user {
  position: absolute;
  width: 459px;
  //   min-height: 500px;
  border-radius: 10px;
  background-color: #1d2025;
  padding: 2rem;
  right: 40%;
  top: 30%;

  &-header {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 134.52%;
    color: #ffffff;
    text-align: center;
  }

  &-login {
    z-index: 9999;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 17px;
    text-align: center;
    text-decoration-line: underline;
    color: #0ef1db;
    cursor: pointer;
  }
}
