@import "../../../utils.scss";

.releaseFinishTextWrapper {
  position: relative;

  top: 474px;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.releaseFinishTitle {
  position: relative;
  width: auto;

  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 2rem;
  line-height: 134.52%;
  /* or 43px */

  text-align: center;

  color: #ffffff;

  @include responsive($mobileL) {
    width: 90%;
  }

  @include responsive($mobile) {
    font-size: 1.5rem;
  }
}

.rfSocialsCheckboxes {
  display: flex;
  justify-content: center;
  color: white;

  &-wrapper {
    display: grid;
    width: 60%;
    row-gap: 0.2rem;

    @include responsive(1200px) {
      width: 80%;
    }

    @include responsive($mobileL) {
      width: 90%;
    }
  }

  &-header {
    padding: 2rem;
    text-align: center;
    font-size: 1.2rem;
  }

  &-section {
    border: 1px solid $primary;
    border-radius: 10px;
    padding: 0.5rem;

    &-loading {
      border: 1px solid $primary;
      border-radius: 10px;
      padding: 0.5rem;
      text-align: center;
      padding-bottom: 2rem;
    }

    &-label {
      position: relative;
      padding: 1rem;
      font-size: 1.5rem;
      text-align: center;
    }

    &-item {
      cursor: pointer;
      display: grid;
      grid-template-columns: 0.25fr 2.5fr 0.5fr;
      padding: 1rem;
      text-align: left;

      &-img {
        width: 50px;
        height: 50px;
        border-radius: 10px;

        img {
          width: 100%;
          height: 100%;
          border-radius: 10px;

          object-fit: contain;
        }
      }

      &-text {
        position: relative;

        padding: 0.8rem;
        padding-left: 0rem;

        @include responsive(1550px) {
          padding-left: 0.8rem;
        }

        @include responsive($mobile) {
          padding-top: 0;
          padding-bottom: 0;
        }

        font-family: Inter-Light;
        font-style: normal;
        font-weight: normal;
        font-size: 1rem;
        /* or 27px */

        color: #ffffff;

        &-inactive {
          padding-right: 0.8rem;
          @include responsive(1550px) {
            padding-left: 0.8rem;
          }
        }

        &-connectNow {
          text-decoration: underline;
          color: $primary;
        }
      }
    }
  }

  @include responsive($mobile) {
    .ptSwitch,
    .ptSwitch-inactive {
      width: 50px;
      height: 30px;

      &-handle {
        width: 20px;
        height: 20px;

        padding-top: 0 !important;

        span {
          font-size: 12px;
          position: relative;
          bottom: 2px;
        }
      }
    }
  }
}

.rfAllPlatformsText {
  position: relative;

  font-family: Inter-Light;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 134.52%;
  /* or 27px */

  color: #ffffff;

  /* Inside Auto Layout */

  flex: none;
  order: 0;
  align-self: center;
  flex-grow: 0;
}

.rfCheckbox {
  display: inline-block;
  position: relative;
  width: 18px;
  height: 18px;
  left: 8px;
  top: 2px;

  border: 2px solid #0ef1db;
  box-sizing: border-box;
  border-radius: 5px;

  &-inactive {
    border: 2px solid grey;
    cursor: default;
  }
}

.rfInputGroup {
  position: absolute;
  width: 300px;
  height: 56px;
  right: 0;
  top: -100px;

  background: #1d2025;
  border: none !important;
  border-radius: 10px;

  box-shadow: none;
}

.rfPlatformsWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;

  grid-row-gap: 12px;
  position: relative;
  width: 950px;
  top: 530px;

  @include center;

  @include responsive($dSmall) {
    width: 90%;
  }

  @include responsive($mobileL) {
    top: 530px;
  }
}

.rfPlatformBox {
  width: 180px;
  height: 180px;

  background: #1d2025;
  border-radius: 10px;
  padding-left: 6px;
  padding-right: 6px;

  text-align: center;

  cursor: pointer;

  @include responsive($dSmall) {
    width: auto;
    height: 180px;
    max-width: 180px;
    // max-height: 180px;

    margin: 0 5px 0 5px;

    flex: 1 1 170px;
  }
}

.rfPlatformBoxText {
  position: relative;
  top: 50px;

  font-family: Inter-Light;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 134.52%;
  /* identical to box height, or 22px */

  color: #ffffff;

  &-comment {
    position: relative;
    top: 55px;
    color: grey;
    font-size: 10px;
  }
}

.rfPlatformBoxCircle {
  position: relative;
  border-radius: 100px;

  width: 72px;
  height: 72px;
  background-color: #3c3c44;

  top: 32px;
  left: 50%;
  transform: translate(-50%, 0);

  @include responsive($dSmall) {
    width: auto;
    height: auto;
    max-width: 72px;
    max-height: 72px;
  }
}

.rfMoreButton {
  position: relative;
  width: 36px;
  height: 36px;
  top: 0;

  @include center;

  border-radius: 100px;

  background: #1d2025;

  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;

  cursor: pointer;
}

.releaseFinishCaptcha {
  display: flex;
  justify-content: center;
}

.applyForReleaseButton {
  position: relative;
  width: 400px;
  height: 56px;
  top: 35px;

  @include center;

  background: #0ef1db;
  border-radius: 10px;

  color: #151619;

  font-size: 18px;
  font-family: Inter-Bold;

  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;

  cursor: pointer;

  @include responsive($mobile) {
    width: 87%;
  }
}

// .allPlatformsButton {
//   margin-bottom: 200px;
// }

.rfAllPlatformsButtonGroup {
  top: 600px;
  position: absolute;
  text-align: center;
  width: 100%;
  height: 400px;
}

.rfButtonInactive {
  background: rgba(255, 255, 255, 0.44);
  cursor: default;
}

.rfPlatformBoxPicked {
  position: relative;
  float: right;

  right: 15px;
  top: 13px;
}

.rfHr {
  position: relative;
  width: 996px;
  height: 0px;
  // top: 1175px;
  left: 3px;

  margin-bottom: 36px;

  @include center;

  z-index: 0;

  opacity: 0.4;
  border: 1px solid #151618;

  @include responsive($dSmall) {
    width: 80%;
  }
}

.rfLowerPlatformsOuterWrapper {
  position: relative;
  top: 570px;
  margin-bottom: 200px;

  @include responsive($mobileL) {
    top: 570px;
  }
}

.rfLowerPlatformsWrapper {
  position: relative;
  top: 0;
  margin-bottom: 30px;
}

.rfTerms {
  margin-top: 12px;
  @include center;

  &-all {
    top: 360px;
  }
}

.rfSlotsFeedback {
  color: red;
  font-size: 13px;
  margin-bottom: 10px;
  line-height: 2rem;
  text-align: center;

  &-warning {
    color: yellow;
    font-size: 13px;
    margin-bottom: 10px;
    line-height: 2rem;
    text-align: center;
  }
}
