.errorPopup {
  position: fixed;
  font-size: 0.5em;
  top: 20px;
  width: 20%;
  background-color: lightgray;
  color: black;
  padding: 10px;
  opacity: 1 !important;
  z-index: 998;
}
