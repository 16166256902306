/* inter */

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  src: url("./assets/fonts/Inter/Inter-Regular.ttf"); /* IE9 Compat Modes */
}

@font-face {
  font-family: "Inter-Bold";
  font-style: normal;
  font-weight: 300;
  src: url("./assets/fonts/Inter/Inter-Bold.ttf"); /* IE9 Compat Modes */
}

@font-face {
  font-family: "Inter-SemiBold";
  font-style: normal;
  font-weight: 300;
  src: url("./assets/fonts/Inter/Inter-SemiBold.ttf"); /* IE9 Compat Modes */
}

@font-face {
  font-family: "Inter-Medium";
  font-style: normal;
  font-weight: 300;
  src: url("./assets/fonts/Inter/Inter-Medium.ttf"); /* IE9 Compat Modes */
}

@font-face {
  font-family: "Inter-Light";
  font-style: normal;
  font-weight: 300;
  src: url("./assets/fonts/Inter/Inter-Light.ttf"); /* IE9 Compat Modes */
}

/* open sans */

@font-face {
  font-family: "OpenSans";
  font-style: normal;
  font-weight: 300;
  src: url("./assets/fonts/OpenSans/OpenSans-Regular.ttf"); /* IE9 Compat Modes */
}

/* fira sans */

@font-face {
  font-family: "FiraSans";
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/FiraSans/FiraSans-Regular.eot"); /* IE9 Compat Modes */
  src: local("Open Sans"), local("OpenSans"),
    url("./assets/fonts/FiraSans/FiraSans-Regular.eot?")
      format("embedded-opentype"),
    url("./assets/fonts/FiraSans/FiraSans-Regular.woff2") format("woff2"),
    url("./assets/fonts/FiraSans/FiraSans-Regular.woff") format("woff"),
    url("./assets/fonts/FiraSans/FiraSans-Regular.ttf") format("truetype"),
    url("./assets/fonts/FiraSans/FiraSans-Regular.svg#OpenSans") format("svg");
}

@font-face {
  font-family: "FiraSansBold";
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/FiraSans/FiraSans-Bold.eot"); /* IE9 Compat Modes */
  src: local("Open Sans"), local("OpenSans"),
    url("./assets/fonts/FiraSans/FiraSans-Bold.eot?")
      format("embedded-opentype"),
    url("./assets/fonts/FiraSans/FiraSans-Bold.woff2") format("woff2"),
    url("./assets/fonts/FiraSans/FiraSans-Bold.woff") format("woff"),
    url("./assets/fonts/FiraSans/FiraSans-Bold.ttf") format("truetype"),
    url("./assets/fonts/FiraSans/FiraSans-Bold.svg#OpenSans") format("svg");
}

@font-face {
  font-family: "FiraSansLight";
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/FiraSans/FiraSans-Light.eot"); /* IE9 Compat Modes */
  src: local("Open Sans"), local("OpenSans"),
    url("./assets/fonts/FiraSans/FiraSans-Light.eot?")
      format("embedded-opentype"),
    url("./assets/fonts/FiraSans/FiraSans-Light.woff2") format("woff2"),
    url("./assets/fonts/FiraSans/FiraSans-Light.woff") format("woff"),
    url("./assets/fonts/FiraSans/FiraSans-Light.ttf") format("truetype"),
    url("./assets/fonts/FiraSans/FiraSans-Light.svg#OpenSans") format("svg");
}

/* industry */

@font-face {
  font-family: "Industry";
  src: url("./assets/fonts/Industry/Industry-Light.eot");
  src: local("Industry Light"), local("Industry-Light"),
    url("./assets/fonts/Industry/Industry-Light.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/Industry/Industry-Light.woff") format("woff"),
    url("./assets/fonts/Industry/Industry-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Industry";
  src: url("./assets/fonts/Industry/Industry-Bold.eot");
  src: local("Industry Bold"), local("Industry-Bold"),
    url("./assets/fonts/Industry/Industry-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/Industry/Industry-Bold.woff") format("woff"),
    url("./assets/fonts/Industry/Industry-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Industry";
  src: url("./assets/fonts/Industry/Industry-BoldItalic.eot");
  src: local("Industry Bold Italic"), local("Industry-BoldItalic"),
    url("./assets/fonts/Industry/Industry-BoldItalic.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/Industry/Industry-BoldItalic.woff") format("woff"),
    url("./assets/fonts/Industry/Industry-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Industry";
  src: url("./assets/fonts/Industry/Industry-LightItalic.eot");
  src: local("Industry Light Italic"), local("Industry-LightItalic"),
    url("./assets/fonts/Industry/Industry-LightItalic.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/Industry/Industry-LightItalic.woff") format("woff"),
    url("./assets/fonts/Industry/Industry-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Industry-Book";
  src: url("./assets/fonts/Industry/Industry-Book.eot");
  src: local("Industry Book"), local("Industry-Book"),
    url("./assets/fonts/Industry/Industry-Book.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/Industry/Industry-Book.woff") format("woff"),
    url("./assets/fonts/Industry/Industry-Book.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Industry";
  src: url("./assets/fonts/Industry/Industry-BlackItalic.eot");
  src: local("Industry Black Italic"), local("Industry-BlackItalic"),
    url("./assets/fonts/Industry/Industry-BlackItalic.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/Industry/Industry-BlackItalic.woff") format("woff"),
    url("./assets/fonts/Industry/Industry-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Industry-BookItalic";
  src: url("./assets/fonts/Industry/Industry-BookItalic.eot");
  src: local("Industry Book Italic"), local("Industry-BookItalic"),
    url("./assets/fonts/Industry/Industry-BookItalic.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/Industry/Industry-BookItalic.woff") format("woff"),
    url("./assets/fonts/Industry/Industry-BookItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Industry-DemiItalic";
  src: url("./assets/fonts/Industry/Industry-DemiItalic.eot");
  src: local("Industry Demi Italic"), local("Industry-DemiItalic"),
    url("./assets/fonts/Industry/Industry-DemiItalic.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/Industry/Industry-DemiItalic.woff") format("woff"),
    url("./assets/fonts/Industry/Industry-DemiItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Industry";
  src: url("./assets/fonts/Industry/Industry-Black.eot");
  src: local("Industry Black"), local("Industry-Black"),
    url("./assets/fonts/Industry/Industry-Black.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/Industry/Industry-Black.woff") format("woff"),
    url("./assets/fonts/Industry/Industry-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Industry";
  src: url("./assets/fonts/Industry/Industry-Medium.eot");
  src: local("Industry Medium"), local("Industry-Medium"),
    url("./assets/fonts/Industry/Industry-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/Industry/Industry-Medium.woff") format("woff"),
    url("./assets/fonts/Industry/Industry-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Industry";
  src: url("./assets/fonts/Industry/Industry-MediumItalic.eot");
  src: local("Industry Medium Italic"), local("Industry-MediumItalic"),
    url("./assets/fonts/Industry/Industry-MediumItalic.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/Industry/Industry-MediumItalic.woff") format("woff"),
    url("./assets/fonts/Industry/Industry-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Industry-Demi";
  src: url("./assets/fonts/Industry/Industry-Demi.eot");
  src: local("Industry Demi"), local("Industry-Demi"),
    url("./assets/fonts/Industry/Industry-Demi.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/Industry/Industry-Demi.woff") format("woff"),
    url("./assets/fonts/Industry/Industry-Demi.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
