@import "../../../utils.scss";

.expenseSplitsCard {
  position: relative;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 10px;
  @include responsive($dSmall) {
    width: 90%;
  }
}

.expenseSplitsContainer {
  max-width: 160px;
  position: relative;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 10px;

  @include responsive($mobileL) {
    max-width: 100%;
  }
}

.expense-splits-header {
  font-family: Inter-SemiBold;
}

.expense-drawer {
  width: 58rem;

  @include responsive($mobileL) {
    width: 100%;
  }
}

.upgrade-message {
  padding: 20px;
  font-size: 18px;
  font-weight: bold;
  margin: 20px;
  color: #fff;
  text-align: center;
  font-family: Inter-SemiBold;
  @include responsive($dSmall) {
    width: 90%;
  }
}

.upgrade-button {
  background: $primary;
  border-radius: 10px;
  padding: 10px 20px;
  font-size: 18px;
  color: #000;
}
.disabled {
  pointer-events: none;
  opacity: 0.4;
}

.input-padding {
  padding: 0 1.75rem;
}
