@import "../../../utils.scss";

.checkboxGroup {
  position: relative;
  float: left;
  font-size: 0.9em;
}

.inputIcon {
  width: 1em;
  filter: contrast(0.05);
}

.ptSwitch {
  position: relative;
  width: 85px;
  height: 46px;

  cursor: pointer;

  background: #1d2025;
  border-radius: 34px;

  @include nonSelect;

  &-inactive {
    position: relative;
    width: 85px;
    height: 46px;

    cursor: default;

    background: #1d2025;
    border-radius: 34px;

    @include nonSelect;

    .ptSwitch-handle {
      background-color: $color-dark-two;
    }
  }

  &-handle {
    position: relative;
    width: 34px;
    height: 34px;
    background: white;
    border-radius: 100px;
    margin: 6px;

    &-off {
      float: left;
    }

    &-on {
      float: right;
      background: $primary;
    }
  }
}
