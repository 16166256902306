@import "../../../utils.scss";

.artist-profile {
  width: 270px;
  min-height: 124px;
  padding: 12px;
  background: $color-dark-one;
  border-radius: 10px;
  @include responsive($mobileL) {
    margin-top: 10px;
  }

  position: relative;
  display: inline-flex;
  align-self: flex-end;

  &-currentlyViewing {
    position: absolute;
    top: -20px;
    color: white;
    font-size: 13px;
  }

  &-img {
    &-wrapper {
      width: 100px;
      height: 100px;
      border-radius: 10px;
      align-self: center;
    }

    width: 100%;
    height: 100%;
    border-radius: 10px;

    &-fit {
      object-fit: cover;
    }
  }

  &-heart {
    position: absolute;
    height: 30px;
    bottom: 2px;
    right: 1px;

    &-wrapper {
      width: 30px;
      height: 100%;
      position: relative;
      bottom: 0;
      right: 0;
    }
  }

  &-pin {
    &-wrapper {
      float: right;
    }
    cursor: pointer;
  }

  &-content {
    margin-left: 14px;
    width: 120px;
    height: 100%;

    &-small {
      font-family: Inter;
      font-style: normal;
      font-size: 12px;
      /* or 16px */

      color: #8e8f92;

      &-primary {
        color: $primary;
        font-family: Inter-SemiBold;
        font-style: normal;
        font-size: 16px;
      }
    }

    &-name {
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;

      color: #ffffff;
    }

    &-genres {
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;

      display: flex;
      align-items: flex-end;

      color: #ffffff;
    }
  }
}
