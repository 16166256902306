$color-dark-one: #1d2025;
$color-dark-two: #343947;
$color-light-one: #33373f;
$color-light-two: #8e8f92;
$primaryColor: #0ef1db;

$mobile: 480px;
$mobileL: 768px;
$dSmaller: 1053px;
$dSmall: 1180px;

@mixin responsive($query) {
  @media (max-width: + $query) {
    @content;
  }
}

.TwoFAButton {
  background: #0ef1db;
  border-radius: 10px;
  text-align: center;
  font-family: Inter-SemiBold;
  letter-spacing: normal;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-user-select: none;
  user-select: none;
}

.TwoFAInput {
  background-color: #33373f;
  border: none;
  height: 100%;
  border-radius: 10px;
  height: 56px;
  font-size: 16px;
  font-family: Inter-Light;

  &:hover,
  &:active,
  &:focus {
    background-color: #343947 !important;
    color: white;
  }

  &:focus,
  &:active {
    border: 1px solid #0ef1db;
    background-color: #343947 !important;
    box-sizing: border-box;
  }
}
