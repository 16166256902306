.smallDarkBlueButton {
  border-radius: 6px;
  box-shadow: 0 4px 6px 0 rgba(50, 50, 93, 0.1), 0 1px 3px 0 rgba(0, 0, 0, 0.08);
  background-color: rgba(29, 44, 71, 0.9);
  width: 90px;
  height: 30px;
  text-align: center;
  color: white;

  &:hover {
    background-color: rgba(52, 70, 117, 0.9);
    color: white;
  }
}

.smallPinkButton {
  border-radius: 4px;
  background-color: #f04467;
  width: 90px;
  height: 38px;
  box-shadow: 0 4px 6px 0 rgba(50, 50, 93, 0.11),
    0 1px 3px 0 rgba(0, 0, 0, 0.08);
  text-align: center;
  font-size: 0.8em;
  color: white;

  &:hover {
    background-color: rgba(219, 92, 120, 0.9);
    color: white;
  }
}

.bigPinkButton {
  border-radius: 4px;
  background-color: #f04467;
  width: 90px;
  height: 38px;
  box-shadow: 0 4px 6px 0 rgba(50, 50, 93, 0.11),
    0 1px 3px 0 rgba(0, 0, 0, 0.08);
  text-align: center;
  font-size: 0.8em;
  color: white;

  &:hover {
    background-color: rgba(219, 92, 120, 0.9);
    color: white;
  }
}

.mainGreenButton {
  border-radius: 4px;
  background-color: #13c1b7;
  width: 90px;
  height: 38px;
  box-shadow: 0 4px 6px 0 rgba(50, 50, 93, 0.11),
    0 1px 3px 0 rgba(0, 0, 0, 0.08);
  text-align: center;
  font-size: 0.8em;
  color: white;

  &:hover {
    background-color: #5dbab1;
    color: white;
  }
}

.spotifyButton {
  font-family: Inter-Bold;
  background: #282c32;
  color: white;

  &:hover {
    background-color: #48945d;
    color: white;
  }
}
