@mixin xyFlexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

@mixin center() {
  left: 50%;
  transform: translate(-50%, 0);
}

$mobileL: 768px;
$mobile: 480px;

@mixin responsive($query) {
  @media (max-width: + $query) {
    @content;
  }
}

.playtreksModal {
  &-overlay {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background: rgba(52, 57, 71, 0.7);
    z-index: 99999;

    @include xyFlexCenter;

    @include responsive($mobileL) {
      background: #1d2025;
    }

    &-insideDashboard {
      height: calc(100vh + 60px);
      top: -50px; // make it cover the whole inside component
    }

    &-insideEditProfile {
      @include responsive($mobileL) {
        left: -10px;
        width: 105vw;
        overflow-y: scroll;
      }
    }
  }

  &-modal {
    position: absolute;
    width: 610px;
    height: 385px;

    background: #1d2025;
    border-radius: 10px;

    z-index: 999;

    font-family: Inter-Light;
    font-style: normal;
    font-size: 20px;
    line-height: 134.52%;
    /* or 27px */

    @include xyFlexCenter;

    color: #ffffff;

    padding: 60px;

    @include responsive($mobileL) {
      padding-right: 100px;
      padding-left: 100px;
    }

    @include responsive($mobile) {
      padding-right: 30vw;
      padding-left: 30vw;
      font-size: 4vw;
    }

    @include responsive(420px) {
      padding-right: 40vw;
      padding-left: 40vw;
    }

    &-close {
      position: relative;
      float: right;
      right: 25px;
      top: -20px;

      cursor: pointer;

      &-absolute {
        position: absolute;
        top: 30px;
        right: 30px;

        @include responsive($mobileL) {
          position: fixed;
          float: right;
          top: 12vw;
          right: 12vw;
        }

        cursor: pointer;

        z-index: 999;
      }

      &-insideEditProfile {
        @include responsive($mobileL) {
          position: fixed;
          top: 12vw;
          right: 10vw;
        }
      }
    }

    &-insideEditProfile {
      @include responsive($mobileL) {
        width: 100%;
        margin-left: 0 !important;
        left: auto !important;
        transform: none !important;
        padding: 45px 20px 0 30px !important;
        min-height: 430px;
      }

      @include responsive($mobile) {
        min-height: 410px;
      }

      &-fbPagePicker {
        margin-top: 30px;
        @include responsive($mobileL) {
          position: relative;
          @include center;
          margin-top: 150px;
          width: 80vw;
        }

        .ob3ProfileBoxes {
          margin-top: 20px;
        }
      }
    }

    &-insideDashboard {
      left: 50%;
      transform: translate(-50%, 0);
      margin-left: -150px;
      margin-top: 30px;

      display: block;

      padding: 45px 0 0 0;
    }

    &-header {
      position: relative;
      height: 27px;

      margin-bottom: 20px;

      // @include center;

      font-family: Inter-Medium;
      font-style: normal;
      font-size: 20px;
      // line-height: 134.52%;
      /* identical to box height, or 27px */

      color: #ffffff;

      display: inline-flex;

      // top: 40px;

      &-editProfile {
        position: absolute;
        left: 45px;
        top: 30px;
      }
    }

    &-btn {
      position: absolute;
      background: #0ef1db;
      border-radius: 10px;

      bottom: 50px;

      width: 244px;
      height: 56px;

      @include xyFlexCenter;

      font-family: Inter;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 134.52%;
      /* or 24px */

      text-align: center;

      color: #151619;

      cursor: pointer;
    }

    &-btn1 {
      position: absolute;
      left: 120px;
      bottom: 40px;
      width: 160px;
      height: 56px;

      @include responsive($mobile) {
        left: 140px;
        font-size: 18px !important;
      }
    }

    &-btn2 {
      position: absolute;
      right: 120px;
      bottom: 40px;
      width: 160px;
      height: 56px;

      @include responsive($mobile) {
        right: 140px;
        font-size: 18px !important;
      }
    }

    &-btnInactive {
      background: rgba(255, 255, 255, 0.44);
    }
  }

  &-textSmall {
    font-size: 16px;
    display: contents;

    @include responsive($mobile) {
      font-size: 3.7vw;
    }
  }

  &-resendBtn {
    margin: 1rem auto;
    &-comment {
      margin: auto;
      font-size: 14px;
      width: 70%;
      font-style: italic;
    }
  }

  &-blockedModalControls {
    position: absolute;
    display: inline;
    z-index: 99999;
    color: white;
    bottom: 20px;
    width: 100%;

    &-left {
      margin-left: 40px;
      float: left;
      cursor: pointer;
      color: #0ef1db;

      &:hover {
        text-decoration: underline;
      }

      &-envelope {
        width: 25px;
        filter: brightness(200%);
      }
    }

    &-right {
      margin-right: 40px;
      float: right;
      cursor: pointer;
      color: #0ef1db;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &-releaseSuccess {
    display: block;

    &-btn {
      position: relative;
      bottom: 0;
      @include center;
    }
  }

  &-quitNewRelease {
    display: block;

    &-btn {
      position: relative;
      bottom: 0;
      @include center;
    }
  }

  &-cancelSubscription {
    display: block;

    &-btn {
      position: relative;
      bottom: 0;
      @include center;
    }
  }

  &-emailVerification {
    display: block;

    @include responsive($mobile) {
      width: 60%;
    }
  }
}
