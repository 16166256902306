@import "../../../utils.scss";

.contract-header{
  font-family: Inter-SemiBold;
}

.add-contact{
  &:hover{
    background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  }
}

.contactsCard {
  position: relative;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 10px;
  @include responsive($dSmall) {
    width: 90%;
  }
}

.contract-drawer-header{
  background-color: rgba(52, 57, 71, 0.7);
  font-size: 1.5rem;
  font-weight: bold;
  font-family: Inter-SemiBold; 
}

.delete-contract {
  font-family: "Inter-Bold";
  border: 1px solid white;
  border-radius: 0.375rem;
  color: white;
  outline: none;
  transition: all 0.3s;
  font-size: 12px; 
}

.contract-details-header{
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  color: #ffffff;
}

.expense-payment-spacing{
  margin-bottom: 50px;
}

.recouping-amount-spacing{
  margin-bottom: 28px;
}