$dSmall: 1024px;
$mobileL: 768px;
$mobile: 480px;

@mixin responsive($query) {
  @media (max-width: + $query) {
    @content;
  }
}

@mixin center() {
  left: 50%;
  transform: translate(-50%, 0);
}

.distributionRoot {
  width: 100vw;
  max-width: 100%;
}

.distrHeaderPlaytreks {
  position: absolute;
  width: 100%;

  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 134.52%;
  /* or 32px */

  // text-align: ;

  color: #ffffff;

  z-index: 9999;

  @include responsive($mobile) {
  }
}

.distrheader {
  &-logo {
    position: absolute;
    top: 74px;
    left: 220px;
    width: 120px;

    @include responsive($dSmall) {
      left: 100px;
    }

    @include responsive($mobileL) {
      top: 60px;
      width: 100px;
    }

    @include responsive($mobile) {
      left: 10vw;
    }

    cursor: pointer;
  }

  &-X {
    position: absolute;
    top: 74px;
    right: 222px;

    @include responsive($dSmall) {
      right: 100px;
    }

    @include responsive($mobile) {
      right: 10vw;
    }

    cursor: pointer;
  }
}

.releaseCardComponent {
  position: relative;
  width: 300px;
  height: 740px;
  background-color: #1d2025;
  border-radius: 10px;
  overflow: hidden;
  font-family: Inter-Medium;
  font-size: 15px;

  @include responsive($mobile) {
    // display: none;
  }
}

.rcImage {
  position: relative;
  width: 300px;
  height: 300px;
  transition: transform 0.2s;
}

.rcName {
  position: relative;
  width: 236px;
  height: auto;
  padding: 0 21px 0 21px;
  margin-top: 16px;

  font-size: 18px;
  line-height: 134.52%;
  /* or 24px */

  color: #ffffff;
}

.rcArtist {
  position: relative; 
  height: auto;
  padding: 3px 21px ;
  color: #ffffff;
}

.rcGenre {
  position: relative;
  //   width: 93px;
  height: 20px;
  padding: 0 21px 0 21px;
  margin-top: 4px;
  font-size: 13px;

  line-height: 134.52%;
  /* or 20px */

  color: #8e8f92;
}

.rcLabel {
  position: relative;
  width: 100%;

  left: 21px;
  top: 44px;

  line-height: 134.52%;
  /* or 20px */
}

.rcUpc {
  position: relative;
  top: 48px;
  left: 21px;
}

.rcHr {
  position: relative;
  width: 259px;
  height: 0px;
  left: 21px;
  top: 62px;

  border: 1px solid #151618;
}

.rcType {
  position: relative;
  top: 72px;
  left: 21px;
}

.rcDate {
  position: relative;
  top: 74px;
  left: 21px;
}

.rcKey {
  color: #8e8f92;
}

.rcValue {
  color: #ffffff;
  width: 100%;
}

.rcCircles {
  top: 90px;
  padding-left: 21px;
  position: relative;
  //   min-width: 100%;
  height: 56px;
}

.rcCircle {
  position: absolute;
  display: inline-block;
  width: 56px;
  height: 56px;
  border-radius: 100px;

  background: #3c3c44;
  border: 2px solid #26252b;
  box-sizing: border-box;
}

.rcc2 {
  margin-left: 37px;
  z-index: 10;
}

.rcc3 {
  margin-left: 74px;
  z-index: 10;
}

.rcc4 {
  margin-left: 111px;
  z-index: 10;
}

.rcc5 {
  margin-left: 148px;
  font-size: 12px;
  z-index: 10;
  padding: 17.5px 18px 17px 8px;
  color: white;
}

.rcc6 {
  margin-left: 185px;
  background-color: #0ef1db;
  z-index: 10;
  padding: 10px 0 0 19px;
  font-size: 20px;
  color: #1d2025;
}

.releaseButton {
  width: 259px;
  height: 56px;
  background: #33383f;
  border-radius: 10px;

  color: #0ef1db;
  font-size: 18px;

  text-align: center;

  position: absolute;
  left: 21px;
  bottom: 16px;

  padding: 14px;
}

.releaseSuccessCard {
  position: absolute;
  top: 670px;

  width: 85%;
  // text-align: center;

  @include center;

  color: white;
  font-size: 16px;
}

.releaseQuality {
  position: absolute;
  top: 670px;

  .rqIcon {
    position: absolute;
    left: 21px;
  }

  .rqTop {
    position: absolute;
    width: 400px;
    color: white;
    font-size: 14px;
    left: 43px;
  }

  .rqTopRed {
    position: absolute;
    width: 400px;
    font-size: 14px;
    left: 43px;
    color: #ff3a81;
  }

  .rqBottom {
    font-family: Inter;
    position: absolute;
    color: #8e8f92;
    width: 400px;
    font-size: 12px;
    top: 22px;
    left: 43px;
  }
}

.uploadTracksButton {
  width: 259px;
  height: 56px;
  background: #33383f;
  border-radius: 10px;

  color: #0ef1db;
  font-size: 18px;

  text-align: center;

  position: absolute;
  left: 21px;
  bottom: 16px;

  padding: 14px;

  cursor: pointer;
}

// Blockchain old code
// .withdrawTracksButton {
//   width: 259px;
//   height: 56px;
//   background: #33383f;
//   border-radius: 10px;

//   color: #0ef1db;
//   font-size: 18px;

//   text-align: center;

//   position: relative;
//   // left: 21px;
//   // bottom: 16px;

//   padding: 14px;

//   cursor: pointer;
//   z-index: 1;
// }

.dspInputWrapper {
  position: absolute;
  top: 797px;
  width: 1082px;

  left: 50%;
  transform: translate(-50%, 0);
}

.dspInputGroup {
  position: relative;
  width: 300px;
  height: 56px;

  float: right;

  background: #1d2025;
  border: none !important;
  border-radius: 10px;

  box-shadow: none;

  @include responsive($mobile) {
    width: 326px;
    height: 56px;
    left: 24px;
    top: 770px;
  }
}

.dspInputGroup.admin-search {
  float: unset;
  width: 100%;

  @include responsive($mobile) {
    left: 0;
    top: 0;
  }
}

.dspInputText {
  background-color: #1d2025;
  border: none;
  border-radius: 10px;
}

.dspInputAddon {
  background-color: #1d2025 !important;
  border: none;
  border-radius: 10px;
}

.dspInput {
  background-color: #1d2025;
  border: none;
  height: 100%;
  border-radius: 10px;

  color: #8e8f92;

  &:hover,
  &:active,
  &:focus {
    background-color: #1d2025;
    color: #8e8f92;
  }
}

.dspSearchIcon {
  position: relative;
  top: 1px;
  left: 7px;
}
