$dSmall: 1024px;
$mobileL: 768px;
$mobile: 480px;

@mixin responsive($query) {
  @media (max-width: + $query) {
    @content;
  }
}

@mixin center() {
  left: 50%;
  transform: translate(-50%, 0);
}

// preview

.distribution-preview {
  &-overview {
    position: relative;
    top: 442px;
  }
}

.previewReleaseCard {
  position: relative;
  width: 857px;
  height: fit-content;
  min-height: 330px;

  @include center;

  background: rgba(255, 255, 255, 0.05);
  border-radius: 10px;

  @include responsive($dSmall) {
    width: 90%;
  }

  @include responsive($mobileL) {
    min-height: 550px;
    height: auto;
  }
}

.prrcRightContainer {
  top: 0;
  margin-left: 330px;
  width: 400px;
  height: 70%;
  top: 10px;

  margin-top: 10px;
  padding-top: 5px;
  padding-bottom: 20px;

  @include responsive($dSmall) {
    width: 50%;
  }

  @include responsive($mobileL) {
    position: relative;
    margin-left: 30px;
    margin-top: 0;
    width: 100%;
  }

  @include responsive($mobile) {
    width: 85%;
  }
}

.prReleaseCardImage {
  // position: sticky;
  width: 300px;
  height: 300px;
  margin-top: 12px;
  margin-left: 12px;

  background: #1d2025 !important;
  border-radius: 10px;

  @include responsive($mobileL) {
    width: 90%;
    max-width: 500px;
    height: auto;
    max-height: 500px;

    margin: 0;
  }

  &-wrapper {
    position: sticky;
    width: 300px;
    height: 10px;
    @include responsive($mobile) {
      height: auto;
    }

    @include responsive($mobileL) {
      top: 0;
      margin-top: 26px;

      height: auto;
      width: auto;

      text-align: center;
    }
  }
}

.prrcTitle {
  position: relative;
  width: auto;
  height: 32px;

  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;

  color: #ffffff;

  // margin-bottom: 12px;
}

.prrcBadge1,
.prrcBadge2 {
  display: inline-flex;
  flex-direction: row;

  position: relative;
  height: 39px;

  padding: 15px;

  background: #1d2025;
  border-radius: 10px;

  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  // line-height: 134.52%;
  /* or 16px */

  color: #ffffff;

  align-items: center;
  text-align: center;
  justify-content: center;

  margin: 0 8px 8px 0;
}

.prrcPrimary,
.prrcContributors {
  position: relative;
  margin-bottom: 20px;
}

.prrcPrimary {
  margin-top: 20px;
}

.prrcParticipant {
  margin-bottom: 8px;
}

.prrcType {
  position: relative;
  display: inline-block;
  font-family: Inter-Light;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 134.52%;
  color: #8e8f92;

  margin-bottom: 10px;

  &-info {
    display: inline-block;
    width: 17px;
    cursor: pointer;
  }
}

.prrcText {
  position: relative;

  font-family: Inter-Medium;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 134.52%;
  /* or 20px */

  color: #8e8f92;
}

.prrcSpaceBottom {
  height: 20px;

  @include responsive($mobileL) {
    display: none;
  }
}

.prrcWhiteText {
  color: white;
}

.prrcEdit,
.prrcShowMore {
  position: absolute !important;

  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 134.52%;
  /* or 20px */

  text-align: right;
  text-decoration-line: underline;

  color: #ffffff;

  opacity: 0.5;

  cursor: pointer;

  right: 30px;

  @include responsive($mobileL) {
    top: 600px;
    right: 50px;
  }
}

.prrcEdit {
  position: absolute;
  top: 20px;

  @include responsive($mobileL) {
    top: 550px;
    right: 30px;
  }

  @include responsive(629px) {
    position: relative;
    top: 90vw;
    right: 8vw;
  }

  @include responsive($mobile) {
    top: 94vw;
  }
}

.prrcShowMore {
  position: absolute;
  bottom: 18px;
}

.prTracksCard {
  position: relative;
  width: 857px;

  margin-top: 50px;

  @include center;

  background: rgba(255, 255, 255, 0.05);
  border-radius: 10px;

  padding-bottom: 100px;

  @include responsive($dSmall) {
    width: 90%;
  }
}

.prtcTitle {
  position: absolute;

  left: 16px;
  top: 16px;

  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 134.52%;
  /* identical to box height, or 43px */

  color: #ffffff;

  z-index: 999;
}

.prtcEdit {
  position: absolute;

  top: 28px;
  right: 16px;

  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 134.52%;
  /* or 20px */

  text-align: right;
  text-decoration-line: underline;

  color: #ffffff;

  opacity: 0.5;

  cursor: pointer;
}

.prtcItemsWrapper {
  position: relative;
  top: 83px;
  left: 16px;

  @include responsive($dSmall) {
    left: auto;
  }
}

.prtcItem {
  position: relative;
  width: 825px;
  height: 58px;

  background: #1d2025;
  border-radius: 10px;

  margin-bottom: 8px;
  padding-left: 18px;

  color: white;

  display: flex;
  align-items: center;
  text-align: left;
  justify-content: left;

  @include responsive($dSmall) {
    width: 95%;
    @include center;
  }
}

.releaseOptionsButton {
  position: relative;
  width: 400px;
  height: 56px;

  background: #0ef1db;
  border-radius: 10px;

  font-family: Inter-Medium;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 134.52%;
  /* or 24px */

  text-align: center;

  color: #151619;

  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;

  cursor: pointer;

  @include responsive($mobile) {
    width: 100%;
  }
}

.releaseOptionsButtonWrapper {
  left: 116px;
  position: relative;
  width: 857px;
  height: 56px;

  margin-top: 60px;

  margin-bottom: 150px;

  @include center;

  @include responsive($dSmall) {
    left: 50px;
    transform: none;
  }

  @include responsive($mobileL) {
    left: 35px;
  }

  @include responsive($mobile) {
    left: 5vw;
    width: 87vw;
  }
}
